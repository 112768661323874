import {
  PLAYER_BUTTON_BEHAVIORS,
  PLAYER_EVENTS_PUBLIC as PLAYER_EVENTS,
  FLOATING_PLAYER_NAVIGATION_MODES,
  MINIMIZED_POSITION,
  MINIPLAYER_SIZE,
} from '../constants';

/**
 * Pass an array of items from map and receive an object with key/value paired map items matching the list
 * @param {object} map
 * @param {[string]} list
 */
export const getMapFilteredOnList = (map, list) => {
  return Object.entries(map)
    .filter(([, value]) => list.includes(value))
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});
};

/**
 * Pass an array of button behaviors and receive an object with key/value paired PLAYER_BUTTON_BEHAVIORS
 * matching the list
 * @param {[string]} listOfButtonBehaviors
 */
export const getPlayerButtonBehaviorsFilteredOnList = (listOfButtonBehaviors) => {
  return getMapFilteredOnList(PLAYER_BUTTON_BEHAVIORS, listOfButtonBehaviors);
};

/**
 * Pass an array of events and receive an object with key/value paired PLAYER_EVENTS
 * matching the list
 * @param {[string]} listOfEvents
 */
export const getPlayerEventsFilteredOnList = (listOfEvents) => {
  return getMapFilteredOnList(PLAYER_EVENTS, listOfEvents);
};

/**
 * Pass an array of floating player navigation modes and receive an object with key/value paired
 * FLOATING_PLAYER_NAVIGATION_MODES matching the list
 * @param {[string]} listOfFloatingPlayerNavigationModes
 */
export const getFloatingPlayerNavigationModesFilteredOnList = (listOfFloatingPlayerNavigationModes) => {
  return getMapFilteredOnList(FLOATING_PLAYER_NAVIGATION_MODES, listOfFloatingPlayerNavigationModes);
};

/**
 * Pass an array of miniplayer's initial positions and receive an object with key/value paired MINIMIZED_POSITION
 * matching the list
 * @param {[string]} listOfMinimizedPositions
 */
export const getMinimizedPositionsFilteredOnList = (listOfMinimizedPositions) => {
  return getMapFilteredOnList(MINIMIZED_POSITION, listOfMinimizedPositions);
};

/**
 * Pass an array of miniplayer's initial sizes and receive an object with key/value paired MINIPLAYER_SIZE
 * matching the list
 * @param {[string]} listOfMiniplayerSizes
 */
export const getMiniplayerSizesFilteredOnList = (listOfMiniplayerSizes) => {
  return getMapFilteredOnList(MINIPLAYER_SIZE, listOfMiniplayerSizes);
};
