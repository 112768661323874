import Url from './url';

/**
 * Generates a player url based a player configuration
 * to load the player in an iframe
 * @param {BambuserLiveShopping} embedInstance
 */
export const generatePlayerIframeUrlForEmbedInstance = (embedInstance) => {
  const {
    config,
    debug,
    isDesktop,
    isNonSupportedBrowser,
    logger,
    _trackingContext,
    _isAppDevEnv,
  } = embedInstance;
  const queryParams = [`type=${config.type}`];

  const pushTruthyParam = (name, value, encode = false, forcedValue) => {
    if (!value) return;
    if (forcedValue !== undefined) value = forcedValue;
    queryParams.push(`${name}=${encode ? encodeURIComponent(value) : value}`);
  };

  const { isParamTrue, isParamFalse } = Url;
  const allowSoundControl = isDesktop && (config.allowSoundControl || isParamTrue('allowSoundControl'));
  const merchantQueryParams = selectQueryParams(window.location.search, config.includeQueryParams);
  const setWithMinimizeSupport = config.hasOwnProperty('withMinimizeSupport');
  const { trimPriceTrailingZeros, checkoutOnCartClick, disableClickOutsideBehavior } = config;

  const isVOD = !!config.videoId;

  if (config.playerVersion) {
    pushTruthyParam('playerVersion', config.playerVersion);
  }
  if (config.isDashboard) {
    pushTruthyParam('isDashboard', config.isDashboard);
  }
  pushTruthyParam('allowFullscreen', config.allowFullscreen, false);
  pushTruthyParam('allowSoundControl', allowSoundControl);
  pushTruthyParam('allowShareAutoplay', config.allowShareAutoplay, false, 1);
  pushTruthyParam('cartType', config.cartType);
  pushTruthyParam('chatName', config.chatName, true);
  // REMARK: VOD in the current form (at least in the case of carousel) is not supported with mini-player behaviour,
  // so opening in a new window is the only option. Technically focus-mode single should support this?
  pushTruthyParam('checkoutTarget', isVOD ? 'new-window' : config.checkoutTarget);
  pushTruthyParam('currency', config.currency);
  pushTruthyParam('debug', debug, true);
  pushTruthyParam('disableChatInput', config.disableChatInput, false);
  pushTruthyParam('deferReactAppInit', config.deferReactAppInit || isParamTrue('deferReactAppInit'));
  pushTruthyParam('eventId', config.eventId || config.videoId);
  pushTruthyParam('isVOD', isVOD); // This tells the player that it should treat the event as a VOD
  pushTruthyParam('landscapeInPortrait', config.landscapeInPortrait);
  pushTruthyParam('hasAcceptedTerms', config.hasAcceptedTerms, false, 1);
  pushTruthyParam('hideActionBar', config.hideActionBar);
  pushTruthyParam('hideCartButton', config.hideCartButton);
  pushTruthyParam('hideCartView', config.hideCartView);
  pushTruthyParam('hideChatOverlay', config.hideChatOverlay);
  pushTruthyParam('hideEmojiOverlay', config.hideEmojiOverlay);
  // Overlay-mode specific override in cases where overlay shouldn't be closable via a player-provided button
  pushTruthyParam('hideCloseButton', config.hideCloseButton, false, 1);
  pushTruthyParam('hideProductList', config.hideProductList);
  pushTruthyParam('hideProductView', config.hideProductView);
  pushTruthyParam('hideShareButton', config.hideShareButton);
  pushTruthyParam('hideShareView', config.hideShareView || config.hideShare);

  // Remove once no client is using 'hideShare' anymore
  if (config.hideShare) {
    logger.warnProdDeprecated(`Player config 'hideShare' is deprecated. Please use 'hideShareView' instead`);
  }
  pushTruthyParam('hideAddToCalendar', config.hideAddToCalendar);
  pushTruthyParam('hideAddToCalendarButton', config.hideAddToCalendarButton);
  pushTruthyParam('locale', config.locale);
  pushTruthyParam('merchantHref', window.location.href.replace(/\?.+/, ''), true);
  pushTruthyParam('merchantQueryParams', merchantQueryParams);
  pushTruthyParam('mockLiveBambuser', config.mockLive, true);
  pushTruthyParam('openProductsTarget', config.openProductsTarget);
  pushTruthyParam('playerContainerNode', config.playerContainerNode, true);
  pushTruthyParam('trimPriceTrailingZeros', trimPriceTrailingZeros || isParamTrue('trimPriceTrailingZeros'));
  pushTruthyParam('shareBaseUrl', config.shareBaseUrl, true);
  pushTruthyParam('shareTargets', config.shareTargets, true);
  pushTruthyParam('showShareButtonInMobileActionBar', config.showShareButtonInMobileActionBar);
  // sourceUrl is only used to detect instagram for escape (internal)
  pushTruthyParam('sourceUrl', config.sourceUrl || window.location.href, true);
  pushTruthyParam('withMinimizeSupport', setWithMinimizeSupport, false, config.withMinimizeSupport ? 1 : 0);
  pushTruthyParam('withoutProductDetails', config.withoutProductDetails, false, '1');
  pushTruthyParam('checkoutOnCartClick', checkoutOnCartClick);
  pushTruthyParam('startMuted', config.startMuted);
  pushTruthyParam('withGA', config.withGA);
  pushTruthyParam('withBaaSPlayerPreloading', config.withBaaSPlayerPreloading || isParamTrue('withBaaSPlayerPreloading'));
  // Signal that this player should automatically played when inititialized
  pushTruthyParam('autoplay', true, false, config.autoplay);
  pushTruthyParam('appDevEnv', _isAppDevEnv);
  pushTruthyParam('hideProducts', config.hideProducts);
  pushTruthyParam('distributionPageId', config.distributionPageId);
  pushTruthyParam('distributionContainerId', config.distributionContainerId);

  let broadcastHash = config.broadcastHash;
  let timestamp = config.timestamp;
  if (config.deeplink && typeof config.deeplink === 'string') {
    const deeplinkParts = config.deeplink.split('@');
    if (deeplinkParts.length === 2 && !!deeplinkParts[0] && !!deeplinkParts[1]) {
      // We have exact 2 deeplink parts, the broadcast hash and the timestamp (hopefully...)
      broadcastHash = deeplinkParts[0];
      timestamp = deeplinkParts[1];
    }
  }

  pushTruthyParam('br', broadcastHash, true);
  pushTruthyParam('time', timestamp, true);
  pushTruthyParam('disableClickOutsideBehavior', disableClickOutsideBehavior);

  if (config.withClientSideProductData) {
    logger.warnProdDeprecated('Use of flag withClientSideProductData is deprecated, please use new product hydration');
    queryParams.push(`withClientSideProductData=${config.withClientSideProductData}`);
  }

  if (_trackingContext) {
    pushTruthyParam('uid', _trackingContext.userId);
    pushTruthyParam('sid', _trackingContext.sessionId);
  }

  if (config.themeId) {
    pushTruthyParam('themeId', config.themeId);
  }

  if (config.orgId) {
    pushTruthyParam('orgId', config.orgId);
  }

  if (config._useExternalPlayerController) {
    pushTruthyParam('_useExternalPlayerController', config._useExternalPlayerController);
  }

  if (isParamTrue('autoLoadPreviousRegistrySnapshot')) {
    pushTruthyParam('autoLoadPreviousRegistrySnapshot', true);
  }

  if (isParamTrue('_useMockPlayerController')) {
    pushTruthyParam('_useMockPlayerController', true);
  }

  if (isParamTrue('useNewImageTransformer')) {
    pushTruthyParam('useNewImageTransformer', true);
  }

  if (isParamTrue('bypassThemeCache')) {
    pushTruthyParam('bypassThemeCache', true);
  }

  if (!isParamFalse('withStatePreloading')) {
    pushTruthyParam('withStatePreloading', true);
  }

  if (!isParamFalse('withChatMessageBackend')) {
    pushTruthyParam('withChatMessageBackend', true);
  }

  if (!isParamFalse('withReactionsBackend')) {
    pushTruthyParam('withReactionsBackend', true);
  }

  if (!isParamFalse('withProductsBackend')) {
    pushTruthyParam('withProductsBackend', true);
  }

  if (!isParamFalse('allowChatBlur')) {
    pushTruthyParam('allowChatBlur', true);
  }

  let iframeUrl = config.playerUrl;
  if (iframeUrl.startsWith('//')) iframeUrl = `${window.location.protocol}${iframeUrl}`;

  if (!iframeUrl.endsWith('/')) iframeUrl += '/'; // End base url with a dash to avoid redundant redirections by CDN
  if (isNonSupportedBrowser) iframeUrl += 'unsupported-browser.html';
  if (queryParams.length > 0) iframeUrl += `?${queryParams.join('&')}`;
  logger.log('_getPlayerUrl() url', iframeUrl, 'config', config);

  return iframeUrl;
};

/**
 * Selects query parameters from a list of query parameters to be included
 * in the query params exposed to the Player
 * @param {string} queryParams - all query parameters as a query string; `?foo=bar&baz=bat`
 * @param {string[]} includeQueryParams
 * @return {string} selected query parameters
 */
export const selectQueryParams = (queryParams, includeQueryParams = []) => {
  if (!queryParams) {
    return '';
  }

  const qps = new URLSearchParams(queryParams);
  const selected = new URLSearchParams();

  for (const k of includeQueryParams) {
    const v = qps.get(k);
    if (v !== null) {
      selected.append(k, v);
    }
  }

  return encodeURIComponent(selected);
};
