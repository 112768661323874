import { VersionedProduct } from './schemaModel';

export default (productId) => {
  // setup the versioned product
  const product = new VersionedProduct(3).getWithId(productId);

  // set hydration default values on the product
  product.vendor('hydratable-product');

  return product;
};
