function getFullscreenElement() {
  return (
    document.fullscreenElement ||
    document.webkitFullscreenElement ||
    document.mozFullScreenElement ||
    document.msFullscreenElement
  );
}

let _fullscreenNode;
export async function enterFullscreen(node) {
  let fullscreenRequest;
  if (node.requestFullscreen) {
    fullscreenRequest = node.requestFullscreen();
  } else if (node.webkitRequestFullscreen) {
    fullscreenRequest = node.webkitRequestFullscreen();
  } else if (node.mozRequestFullScreen) {
    fullscreenRequest = node.mozRequestFullScreen();
  } else if (node.msRequestFullscreen) {
    fullscreenRequest = node.msRequestFullscreen();
  }

  if (fullscreenRequest) {
    try {
      await fullscreenRequest;
      _fullscreenNode = node;
    } catch (err) {
      throw new Error(
        'Could not enter fullscreen. If using an iframe, ensure the allow="fullscreen" attribute is set.',
      );
    }
  }
}

export function exitFullscreen() {
  const fullscreenElement = getFullscreenElement();
  if (!fullscreenElement) return;

  // Exit fullscreen if the current fullscreen node is ours
  if (fullscreenElement === _fullscreenNode) {
    if (document.exitFullscreen) return document.exitFullscreen();
    if (document.webkitExitFullscreen) return document.webkitExitFullscreen();
    if (document.mozCancelFullScreen) return document.mozCancelFullScreen();
    if (document.msExitFullscreen) return document.msExitFullscreen();
  }
}

export function isFullscreen() {
  const fullscreenElement = getFullscreenElement();
  return !!fullscreenElement && fullscreenElement === _fullscreenNode;
}

export function listenToFullscreenEvents(node, callback) {
  node.addEventListener('fullscreenchange', callback);
  return () => node.removeEventListener('fullscreenchange', callback);
}
