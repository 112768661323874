export const PLAYER_EVENT_PREFIX = 'livecommerce:';
export const PLAYER_EVENTS = {
  EXTERNAL_EVENT_HANDLERS: `${PLAYER_EVENT_PREFIX}external-event-handlers`,
  ADD_TO_CART: `${PLAYER_EVENT_PREFIX}should-add-item-to-cart`,
  CHAT_MESSAGES: `${PLAYER_EVENT_PREFIX}chat-messages`,
  CHECKOUT: `${PLAYER_EVENT_PREFIX}goto-checkout`,
  CLOSE: `${PLAYER_EVENT_PREFIX}close`,
  CURRENT_BASEPAGE_SCROLLTOP: `${PLAYER_EVENT_PREFIX}current-basepage-scrolltop`,
  CURRENT_BASEPAGE_VIEWPORT: `${PLAYER_EVENT_PREFIX}current-basepage-viewport`,
  DO_SYNC_CART_STATE: `${PLAYER_EVENT_PREFIX}sync-cart-state`,
  FOCUS_FIRST_INSIDE_IFRAME: `${PLAYER_EVENT_PREFIX}focus-first-inside-iframe`,
  FOCUS_FIRST_OUTSIDE_IFRAME: `${PLAYER_EVENT_PREFIX}focus-first-outside-iframe`,
  FOCUS_LAST_INSIDE_IFRAME: `${PLAYER_EVENT_PREFIX}focus-last-inside-iframe`,
  FOCUS_LAST_OUTSIDE_IFRAME: `${PLAYER_EVENT_PREFIX}focus-last-outside-iframe`,
  FOCUS_LEAVE: `${PLAYER_EVENT_PREFIX}focus-leave`,
  FULLSCREEN_ENTER: `${PLAYER_EVENT_PREFIX}fullscreen-enter`,
  FULLSCREEN_EXIT: `${PLAYER_EVENT_PREFIX}fullscreen-exit`,
  HIDE_CART: `${PLAYER_EVENT_PREFIX}should-hide-cart`,
  HIDE_CHAT_OVERLAY: `${PLAYER_EVENT_PREFIX}should-hide-chat-overlay`,
  HIDE_PRODUCT_LIST: `${PLAYER_EVENT_PREFIX}should-hide-product-list`,
  HIDE_UI: `${PLAYER_EVENT_PREFIX}should-hide-ui`,
  MINI_PLAYER_DRAG_END: `${PLAYER_EVENT_PREFIX}mini-player-drag-end`,
  MINI_PLAYER_MOVE: `${PLAYER_EVENT_PREFIX}mini-player-move`,
  MINI_PLAYER_RESTORE: `${PLAYER_EVENT_PREFIX}mini-player-restore`,
  MINIMIZE: `${PLAYER_EVENT_PREFIX}minimize`,
  MINIMIZED_ACTION_OVERLAY: `${PLAYER_EVENT_PREFIX}minimized-action-overlay`,
  NAVIGATE_BEHIND_TO: `${PLAYER_EVENT_PREFIX}navigate-behind-to`,
  ON_PROGRESS: `${PLAYER_EVENT_PREFIX}on-progress-update`,
  OPEN_EXTERNAL_BROWSER_SUCCESS: `${PLAYER_EVENT_PREFIX}open-external-browser-success`,
  OPEN_EXTERNAL_BROWSER: `${PLAYER_EVENT_PREFIX}open-external-browser`,
  PLAYER_SWIPE_DOWN: `${PLAYER_EVENT_PREFIX}player-swipe-down`,
  PLAYER_SWIPE_LEFT: `${PLAYER_EVENT_PREFIX}player-swipe-left`,
  PLAYER_SWIPE_RIGHT: `${PLAYER_EVENT_PREFIX}player-swipe-right`,
  PLAYER_SWIPE_UP: `${PLAYER_EVENT_PREFIX}player-swipe-up`,
  PROVIDE_PRODUCT_DATA: `${PLAYER_EVENT_PREFIX}provide-product-data`,
  LOAD: `${PLAYER_EVENT_PREFIX}load`,
  READY: `${PLAYER_EVENT_PREFIX}ready`,
  RESET_BASE_PAGE_SCROLL: `${PLAYER_EVENT_PREFIX}reset-base-page-scroll`,
  RESPONSE: `${PLAYER_EVENT_PREFIX}response`,
  SET_CLOSE_ICON: `${PLAYER_EVENT_PREFIX}set-close-icon`,
  SET_RESTORE_ICON: `${PLAYER_EVENT_PREFIX}set-restore-icon`,
  SET_VIEWPORT_STATE: `${PLAYER_EVENT_PREFIX}set-viewport-state`,
  SET_VIDEO_STATE: `${PLAYER_EVENT_PREFIX}set-video-state`,
  SHOW_ADD_TO_CALENDAR: `${PLAYER_EVENT_PREFIX}should-show-add-to-calendar`,
  SHOW_EMOJI_BATCH: `${PLAYER_EVENT_PREFIX}should-show-emoji-batch`,
  SHOW_CART: `${PLAYER_EVENT_PREFIX}should-show-cart`,
  SHOW_CHAT_OVERLAY: `${PLAYER_EVENT_PREFIX}should-show-chat-overlay`,
  SHOW_PRODUCT_LIST: `${PLAYER_EVENT_PREFIX}should-show-product-list`,
  SHOW_PRODUCT_VIEW: `${PLAYER_EVENT_PREFIX}should-show-product-view`,
  HIDE_PRODUCT_VIEW: `${PLAYER_EVENT_PREFIX}should-hide-product-view`,
  SHOW_SHARE: `${PLAYER_EVENT_PREFIX}should-show-share`,
  SHOW_UI: `${PLAYER_EVENT_PREFIX}should-show-ui`,
  SUBSCRIBE: `${PLAYER_EVENT_PREFIX}subscribe`,
  SYNC_CART_STATE: `${PLAYER_EVENT_PREFIX}should-sync-cart-state`,
  TRACKING_POINT: `${PLAYER_EVENT_PREFIX}tracking-point`,
  TRACKING_SEND_EVENT: `${PLAYER_EVENT_PREFIX}tracking-send-event`,
  UNSUBSCRIBE: `${PLAYER_EVENT_PREFIX}unsubscribe`,
  UPDATE_ITEM_IN_CART: `${PLAYER_EVENT_PREFIX}should-update-item-in-cart`,
  UPDATE_SHOW_STATUS: `${PLAYER_EVENT_PREFIX}should-update-show-status`,
  UPDATE_PRODUCT_HIGHLIGHT: `${PLAYER_EVENT_PREFIX}should-update-product-highlight`,
  PRODUCT_HIGHLIGHT_LIST: `${PLAYER_EVENT_PREFIX}product-highlight-list`,
  NOTIFY_URL_CHANGE: `${PLAYER_EVENT_PREFIX}notify-url-change`,
  EXTERNAL_PLAYER_CONTROLLER_NEW: `${PLAYER_EVENT_PREFIX}external-player-controller-new`,
  EXTERNAL_PLAYER_CONTROLLER_CHANGE: `${PLAYER_EVENT_PREFIX}external-player-controller-change`,
  EXTERNAL_PLAYER_CONTROLLER_EVENTS: `${PLAYER_EVENT_PREFIX}external-player-controller-events`,
  EXTERNAL_PLAYER_CONTROLLER_PLAY: `${PLAYER_EVENT_PREFIX}external-player-controller-play`,
  EXTERNAL_PLAYER_CONTROLLER_PAUSE: `${PLAYER_EVENT_PREFIX}external-player-controller-pause`,
  EXTERNAL_PLAYER_CONTROLLER_MUTE: `${PLAYER_EVENT_PREFIX}external-player-controller-mute`,
  EXTERNAL_PLAYER_CONTROLLER_SEEK: `${PLAYER_EVENT_PREFIX}external-player-controller-seek`,
  EXTERNAL_PLAYER_CONTROLLER_LOAD: `${PLAYER_EVENT_PREFIX}external-player-controller-load`,
  EXTERNAL_PLAYER_CONTROLLER_HIDE: `${PLAYER_EVENT_PREFIX}external-player-controller-hide`,
  EXTERNAL_PLAYER_CONTROLLER_SHOW: `${PLAYER_EVENT_PREFIX}external-player-controller-show`,
  EXTERNAL_PLAYER_CONTROLLER_REMOVE: `${PLAYER_EVENT_PREFIX}external-player-controller-remove`,
  PLAY: `${PLAYER_EVENT_PREFIX}play`,
  PAUSE: `${PLAYER_EVENT_PREFIX}pause`,
  MUTE: `${PLAYER_EVENT_PREFIX}mute`,
  UNMUTE: `${PLAYER_EVENT_PREFIX}unmute`,
  SEEK_TO_PERCENT: `${PLAYER_EVENT_PREFIX}seek-to-percent`,
  PLAYBACK_STATUS: `${PLAYER_EVENT_PREFIX}playback-status`,
  PLAYER_CONTAINER_UPDATE: `${PLAYER_EVENT_PREFIX}player-container-update`,
  REQUEST_PICTURE_IN_PICTURE: `${PLAYER_EVENT_PREFIX}request-picture-in-picture`,
  EXIT_PICTURE_IN_PICTURE: `${PLAYER_EVENT_PREFIX}exit-picture-in-picture`,
  ENTERED_PICTURE_IN_PICTURE: `${PLAYER_EVENT_PREFIX}entered-picture-in-picture`,
  EXITED_PICTURE_IN_PICTURE: `${PLAYER_EVENT_PREFIX}exited-picture-in-picture`,
};
export const PLAYER_EVENTS_PUBLIC = Object.entries(PLAYER_EVENTS).reduce((acc, [key, value]) => {
  acc[key] = value.replace(PLAYER_EVENT_PREFIX, '');
  return acc;
}, {});

export const SURF_FRAME_EVENT_PREFIX = 'livecommerce-surf:';
export const SURF_FRAME_EVENTS = {
  ACCEPTED_REQUEST_OPEN_SHOW: `${SURF_FRAME_EVENT_PREFIX}accepted-request-open-show`,
  REQUEST_OPEN_SHOW: `${SURF_FRAME_EVENT_PREFIX}request-open-show`,
  REQUEST_SURF_BEHIND_TO: `${SURF_FRAME_EVENT_PREFIX}request-surf-behind-to`,
};

export const PLAYER_BUTTON_BEHAVIORS = {
  AUTO: 'auto',
  CLOSE: 'close',
  INLINE: 'inline',
  LINK: 'link',
  MINIMIZE: 'minimize',
  NONE: 'none',
};

export const PLAYER_CART_EVENTS = [
  PLAYER_EVENTS.ADD_TO_CART,
  PLAYER_EVENTS.CHECKOUT,
  PLAYER_EVENTS.PROVIDE_PRODUCT_DATA,
  PLAYER_EVENTS.UPDATE_ITEM_IN_CART,
];

export const PLAYER_UI_EVENTS = [
  PLAYER_EVENTS.CHAT_MESSAGES,
  PLAYER_EVENTS.HIDE_PRODUCT_LIST,
  PLAYER_EVENTS.HIDE_CART,
  PLAYER_EVENTS.HIDE_CHAT_OVERLAY,
  PLAYER_EVENTS.PLAYER_SWIPE_DOWN,
  PLAYER_EVENTS.PLAYER_SWIPE_LEFT,
  PLAYER_EVENTS.PLAYER_SWIPE_RIGHT,
  PLAYER_EVENTS.PLAYER_SWIPE_UP,
  PLAYER_EVENTS.SHOW_ADD_TO_CALENDAR,
  PLAYER_EVENTS.SHOW_EMOJI_BATCH,
  PLAYER_EVENTS.SHOW_PRODUCT_LIST,
  PLAYER_EVENTS.SHOW_CART,
  PLAYER_EVENTS.SHOW_CHAT_OVERLAY,
  PLAYER_EVENTS.SHOW_PRODUCT_VIEW,
  PLAYER_EVENTS.HIDE_PRODUCT_VIEW,
  PLAYER_EVENTS.SHOW_SHARE,
  PLAYER_EVENTS.SUBSCRIBE,
  PLAYER_EVENTS.UNSUBSCRIBE,
  PLAYER_EVENTS.UPDATE_SHOW_STATUS,
  PLAYER_EVENTS.UPDATE_PRODUCT_HIGHLIGHT,
  PLAYER_EVENTS.NOTIFY_URL_CHANGE,
  PLAYER_EVENTS.PLAYBACK_STATUS,
  PLAYER_EVENTS.PLAYER_CONTAINER_UPDATE,
  PLAYER_EVENTS.ENTERED_PICTURE_IN_PICTURE,
  PLAYER_EVENTS.EXITED_PICTURE_IN_PICTURE,
];

export const PLAYER_MISC_EVENTS = [
  PLAYER_EVENTS.LOAD,
];

export const FLOATING_PLAYER_NAVIGATION_MODES = {
  IFRAME_SRCDOC: 'srcdoc',
  IFRAME: 'iframe',
  MANUAL: 'manual',
};

export const PLAYER_EVENTS_TO_FORWARD = [].concat(PLAYER_CART_EVENTS, PLAYER_UI_EVENTS, PLAYER_MISC_EVENTS);

export const GA_MEASUREMENT_ID = { STAGE: 'G-2GD03VB4T7', PROD: 'G-MY3DDVP96E' };
export const BAMLS = '_bamls';

/**
 * Defines names of tracking cookies
 */
export const TRACKING_COOKIES = {
  CUSTOMER_ID: `${BAMLS}_cuid`,
  LAST_INTERACTION_TIMESTAMP: `${BAMLS}_lits`,
  SESSION_ID: `${BAMLS}_seid`,
  SHOW_ID: `${BAMLS}_shid`,
  USER_ID: `${BAMLS}_usid`,
};

export const ORIENTATION = {
  EAST: 'E',
  NORTH_EAST: 'NE',
  NORTH_WEST: 'NW',
  NORTH: 'N',
  SOUTH_EAST: 'SE',
  SOUTH_WEST: 'SW',
  SOUTH: 'S',
  WEST: 'W',
};

export const MINIMIZED_POSITION = {
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_RIGHT: 'bottom-right', // default
  TOP_LEFT: 'top-left',
  TOP_RIGHT: 'top-right',
};

export const MINIPLAYER_SIZE = {
  SMALL: 'small',
  LARGE: 'large',
};

export const VIDEO_ORIENTATION = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
};
