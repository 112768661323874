import { BambuserLiveShoppingError } from '../errors/BambuserLiveShoppingError';
import { BambuserLiveShoppingPlayerAPIEmbedInstanceAdapter } from './BambuserLiveShoppingPlayerAPIEmbedInstanceAdapter';
import {
  PLAYER_EVENTS_PUBLIC as PLAYER_EVENTS,
  PLAYER_BUTTON_BEHAVIORS,
  FLOATING_PLAYER_NAVIGATION_MODES,
  MINIMIZED_POSITION,
  MINIPLAYER_SIZE,
} from '../constants';
import { BambuserLiveShoppingConfiguration } from './BambuserLiveShoppingConfiguration';
import { BambuserLiveShoppingPlayerAPI } from './BambuserLiveShoppingPlayerAPI';

/**
 * Bambuser Player API Delegate
 * - Handles all communication to and from the BambuserLiveShoppingPlayerAPI
 * - Is allowed breaking changes, as long as they're compatible with the public API
 */
export class BambuserLiveShoppingPlayerAPIDelegate {
  /**
   * Creates a new instance of a BambuserLiveShoppingPlayerAPIDelegate
   * @param {BambuserLiveShoppingPlayerAPIEmbedInstanceAdapter} embedInstance
   * @param {object} [options]
   * @param {BambuserLiveShoppingConfiguration} [options.configurationClass] optionally set which configuration class to use
   */
  constructor(embedInstance, {
    configurationClass = BambuserLiveShoppingConfiguration,
  } = {}) {
    if (!(embedInstance instanceof BambuserLiveShoppingPlayerAPIEmbedInstanceAdapter)) {
      throw new BambuserLiveShoppingError(
        `Cannot create a new BambuserLiveShoppingPlayerAPIDelegate without a binding adapter for embed.js
        (BambuserLiveShoppingPlayerAPIEmbedInstanceAdapter)`,
      );
    }

    if (
      typeof configurationClass !== 'function' ||
      !(
        configurationClass.prototype instanceof BambuserLiveShoppingConfiguration ||
        configurationClass.prototype.constructor.name === BambuserLiveShoppingConfiguration.prototype.constructor.name
      )
    ) {
      throw new BambuserLiveShoppingError(
        `Cannot create a new BambuserLiveShoppingPlayerAPIDelegate without a valid configurationClass`,
      );
    }

    this._config = new configurationClass();
    this._embedInstance = embedInstance;
    this._eventSubscribers = {};
    this._mounted = false;
    this._publicAPI = new BambuserLiveShoppingPlayerAPI(this);

    this.resetPlayerControllers();
  }

  /**
   * Get configuration instance
   * @return {BambuserLiveShoppingConfiguration}
   */
  get config() {
    return this._config;
  }

  /**
   * Get an adapter for the current Embed Instance that owns the Delegate
   * @return {BambuserLiveShoppingPlayerAPIEmbedInstanceAdapter}
   */
  get embedInstance() {
    return this._embedInstance;
  }

  /**
   * Get the map of all event subscribers
   * @return {object}
   */
  get eventSubscribers() {
    return this._eventSubscribers;
  }

  /**
   * Gets Public Player API for this Delegate instance
   * @returns {BambuserLiveShoppingPlayerAPI}
   */
  get publicAPI() {
    return this._publicAPI;
  }

  /**
   * @constant publicButtonBehaviors
   * Controls which button behaviors should be mapped as props on public API
   * @return {array}
   */
  get publicButtonBehaviors() {
    return Object.values(PLAYER_BUTTON_BEHAVIORS);
  }

  /**
   * @constant publicEvents
   * Controls which events should reach the public API
   * These will also be mapped as props on public API
   */
  get publicEvents() {
    return [
      PLAYER_EVENTS.ADD_TO_CART,
      PLAYER_EVENTS.CHAT_MESSAGES,
      PLAYER_EVENTS.CHECKOUT,
      PLAYER_EVENTS.CLOSE,
      PLAYER_EVENTS.HIDE_PRODUCT_LIST,
      PLAYER_EVENTS.HIDE_CART,
      PLAYER_EVENTS.HIDE_CHAT_OVERLAY,
      PLAYER_EVENTS.NAVIGATE_BEHIND_TO,
      PLAYER_EVENTS.PLAYER_SWIPE_DOWN,
      PLAYER_EVENTS.PLAYER_SWIPE_LEFT,
      PLAYER_EVENTS.PLAYER_SWIPE_RIGHT,
      PLAYER_EVENTS.PLAYER_SWIPE_UP,
      PLAYER_EVENTS.PROVIDE_PRODUCT_DATA,
      PLAYER_EVENTS.LOAD,
      PLAYER_EVENTS.READY,
      PLAYER_EVENTS.SHOW_ADD_TO_CALENDAR,
      PLAYER_EVENTS.SHOW_EMOJI_BATCH,
      PLAYER_EVENTS.SHOW_PRODUCT_LIST,
      PLAYER_EVENTS.SHOW_CART,
      PLAYER_EVENTS.SHOW_CHAT_OVERLAY,
      PLAYER_EVENTS.SHOW_PRODUCT_VIEW,
      PLAYER_EVENTS.HIDE_PRODUCT_VIEW,
      PLAYER_EVENTS.UPDATE_SHOW_STATUS,
      PLAYER_EVENTS.UPDATE_PRODUCT_HIGHLIGHT,
      PLAYER_EVENTS.SHOW_PRODUCT_LIST,
      PLAYER_EVENTS.SHOW_SHARE,
      PLAYER_EVENTS.SUBSCRIBE,
      PLAYER_EVENTS.SYNC_CART_STATE,
      PLAYER_EVENTS.UNSUBSCRIBE,
      PLAYER_EVENTS.UPDATE_ITEM_IN_CART,
      PLAYER_EVENTS.NOTIFY_URL_CHANGE,
      PLAYER_EVENTS.EXTERNAL_PLAYER_CONTROLLER_NEW,
      PLAYER_EVENTS.EXTERNAL_PLAYER_CONTROLLER_CHANGE,
      PLAYER_EVENTS.EXTERNAL_PLAYER_CONTROLLER_PLAY,
      PLAYER_EVENTS.EXTERNAL_PLAYER_CONTROLLER_PAUSE,
      PLAYER_EVENTS.EXTERNAL_PLAYER_CONTROLLER_SEEK,
      PLAYER_EVENTS.EXTERNAL_PLAYER_CONTROLLER_LOAD,
      PLAYER_EVENTS.EXTERNAL_PLAYER_CONTROLLER_HIDE,
      PLAYER_EVENTS.EXTERNAL_PLAYER_CONTROLLER_SHOW,
      PLAYER_EVENTS.EXTERNAL_PLAYER_CONTROLLER_REMOVE,
      PLAYER_EVENTS.EXTERNAL_PLAYER_CONTROLLER_MUTE,
      PLAYER_EVENTS.PLAYBACK_STATUS,
      PLAYER_EVENTS.PLAYER_CONTAINER_UPDATE,
      PLAYER_EVENTS.ENTERED_PICTURE_IN_PICTURE,
      PLAYER_EVENTS.EXITED_PICTURE_IN_PICTURE,
    ];
  }

  /**
   * @constant publicFloatingPlayerNavigationModes
   * Controls which floating player navigation modes should be mapped as props on public API
   * @return {array}
   */
  get publicFloatingPlayerNavigationModes() {
    return Object.values(FLOATING_PLAYER_NAVIGATION_MODES);
  }

  /**
   * @constant publicMinimizedPositions
   * Controls which minimized positions should be mapped as props on public API
   * @return {array}
   */
  get publicMinimizedPositions() {
    return Object.values(MINIMIZED_POSITION);
  }

  /**
   * @constant publicMiniplayerSizes
   * Controls which miniplayer sizes should be mapped as props on public API
   * @return {array}
   */
  get publicMiniplayerSizes() {
    return Object.values(MINIPLAYER_SIZE);
  }

  /**
   * Get list of events that have been subscribed to
   * by end user
   * @return {array} list of events
   */
  getSupportedEvents() {
    return Object.keys(this.eventSubscribers);
  }

  /**
   * Check if API has mounted itself
   * @return {boolean}
   */
  isMounted() {
    return this._mounted;
  }

  /**
   * Mount the API on a mountPoint on mountContext
   * @param {object} mountContext usually window
   * @param {callable} mountPoint the method of which end user will get the instance
   * @param {boolean} force mount the API even if already mounted
   * @throws BambuserLiveShoppingError
   */
  mount(mountContext, mountPoint, eventId, force = false) {
    const { logger } = this.embedInstance._embedInstance;

    if (this._mounted && !force) {
      logger && logger.log('mount() API already mounted');
      return;
    }
    if (!mountContext)
      throw new BambuserLiveShoppingError('Missing mountContext when mounting BambuserLiveShoppingPlayerAPIDelegate');
    if (typeof mountContext[mountPoint] !== 'function')
      throw new BambuserLiveShoppingError(`Couldnt find method "${mountPoint}" on provided mountContext`);

    logger && logger.log('mount() API is mounting...');
    this._mountContext = mountContext;
    this._mountPoint = mountPoint;

    try {
      this._publicAPI.removeAllListeners();
      this._mountContext[this._mountPoint](this._publicAPI, eventId);
      this._mounted = true;
      logger && logger.log('mount() API is now mounted');
    } catch (e) {
      logger && logger.error(`mount() API mounting failed`, e.message);
    }
  }

  /**
   * Push event to the public API
   * @param {string} eventName
   * @param {object} [event]
   * @param {object} [event.data] optional meta data for event
   * @param {callable} [event.callback] optional callback for event
   */
  pushEvent(eventName, { data: eventData, callback } = {}) {
    const eventHandlers = this.eventSubscribers[eventName];
    if (Array.isArray(eventHandlers))
      eventHandlers.forEach((h) => {
        try {
          h(eventData, callback);
        } catch (e) {
          const { logger } = this.embedInstance._embedInstance;
          logger && logger.error(`Event handler for event "${eventName}" threw the exception`, e);
        }
      });
  }

  currentPlayerController() {
    return this._allPlayerControllers[this._currentPlayerControllerId];
  }

  playerControllers() {
    return this._allPlayerControllers;
  }

  setupNewPlayerController(id) {
    if (this._allPlayerControllers[id]) return; // already exists
    this._allPlayerControllers[id] = { id };
  }

  changeCurrentPlayerController(id) {
    this._currentPlayerControllerId = id;
  }

  resetPlayerControllers() {
    this._currentPlayerControllerId = null;
    this._allPlayerControllers = {};
  }

  off(eventName, handler) {
    if (!this.publicEvents.includes(eventName))
      throw new BambuserLiveShoppingError(`Trying to unsubscribe to non existing event "${eventName}"`);
    if (!Array.isArray(this.eventSubscribers[eventName]))
      throw new BambuserLiveShoppingError('Trying to unsubscribe to a an event without subscribers');

    const subscriberId = this.eventSubscribers[eventName].indexOf(handler);
    this.eventSubscribers[eventName].splice(subscriberId, 1);
  }

  on(eventName, handler) {
    if (!this.publicEvents.includes(eventName))
      throw new BambuserLiveShoppingError(`Trying to subscribe to non existing event "${eventName}"`);
    if (!Array.isArray(this.eventSubscribers[eventName])) this.eventSubscribers[eventName] = [];

    this.eventSubscribers[eventName].push(handler);

    // Notify the outermost layer about the added event handler
    // so it can tell the player once it's been started
    this._embedInstance?._embedInstance?._onPlayerApiEventHandlerAdded(eventName);
  }
}
