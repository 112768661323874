const camelize = (str) => str.replace(/-([a-z])/g, (c) => c[1].toUpperCase());
const kebabize = (string) => string.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();

const MAX_SETTINGS_LENGTH = 350;

const PLAYER_SETTINGS = {
  products: {
    defaultValue: true,
    isValidValue: (value) => value === 'on' || value === 'off' || value === 'true' || value === 'false',
    getValue: (value) => value === 'on' || value === 'true',
  },
  title: {
    defaultValue: true,
    isValidValue: (value) => value === 'on' || value === 'off' || value === 'true' || value === 'false',
    getValue: (value) => value === 'on' || value === 'true',
  },
  overlayTextWrap: {
    defaultValue: true,
    isValidValue: (value) => value === 'true' || value === 'false',
    getValue: (value) => value === 'on' || value === 'true',
  },
  productCardMode: {
    defaultValue: 'default',
    isValidValue: (value) => ['default', 'thumbnail', 'full'].includes(value),
    getValue: (value) => value,
  }
};

const parsePlayerSettingValue = (key, value) => {
  const camelizedKey = camelize(key);
  if (!(camelizedKey in PLAYER_SETTINGS)) {
    console.warn(`Invalid setting name: ${key}`);
    return;
  }

  if (!PLAYER_SETTINGS[camelizedKey].isValidValue(value)) {
    console.warn(`Invalid setting ${key} value: ${value}`);
    return;
  }

  return PLAYER_SETTINGS[camelizedKey].getValue(value);
};

export const parsePlayerSettings = (settingsString) => {
  if (MAX_SETTINGS_LENGTH < settingsString.length) {
    console.warn(`Settings string is too long: ${settingsString.length} characters`);
    return {};
  }

  const parsedSettings = {};

  const settingStrings = settingsString.split(';');
  for (const settingString of settingStrings) {
    const settingKVEntry = settingString.split(':').map((s) => s.trim());
    if (settingKVEntry.length !== 2) {
      console.warn(`Invalid settings entry: ${settingKVEntry}`);
      continue;
    }

    const [key, value] = settingKVEntry;

    const parsedValue = parsePlayerSettingValue(key, value);

    if (parsedValue === undefined) {
      // Unknown key or invalid value, ignoring this setting
      continue;
    }

    parsedSettings[camelize(key)] = parsedValue;
  }

  return parsedSettings;
};

export const getSettingsString = (settings) =>
  Object.entries(settings)
    .map(([key, value]) => `${kebabize(key)}:${value}`)
    .join(';');

export const getDefaultPlayerSettings = () =>
  Object.fromEntries(Object.entries(PLAYER_SETTINGS).map(([key, { defaultValue }]) => [key, defaultValue]));
