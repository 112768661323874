'use strict';
import { v4 as uuidv4 } from 'uuid';

/**
 * Post a message to Sentry without the bulk of the official Sentry client
 *
 * "captureMessage" is the method name used by the proper Sentry client.
 * Using the same name here might avoid some future refactoring in case
 * we need to upgrade to the proper client.
 */
const captureMessage = (message, level = 'info') => {
  if (process.env.NODE_ENV === 'development') return;
  if (!message) return;
  if (!['fatal','error','warning','log','info','debug','critical'].includes(level)) return;
  const payload = {
    message,
    level,
    event_id: uuidv4().replace(/-/g, ''), // UUIDs are a bit too long for Sentry's liking
    platform: 'javascript',
    timestamp: Date.now() / 1000,
    request: {
      url: window.location.href,
      headers: {
        'User-Agent': window.navigator.userAgent,
      }
    },
  };

  if (!window.fetch) return;
  window.fetch('https://sentry.bambuser.com/api/10/store/?sentry_key=c49605370c354874b15fb74f834659cf&sentry_version=7', {
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

export { captureMessage };
