import { BambuserLiveShoppingError } from '../errors/BambuserLiveShoppingError';
import { isPlainObject } from '../utils/helpers';
import CreateProduct from '../utils/productSpecGenerator';
import { SchemaModel } from '../utils/schemaModel/SchemaModel';
import {
  getPlayerButtonBehaviorsFilteredOnList,
  getPlayerEventsFilteredOnList,
  getFloatingPlayerNavigationModesFilteredOnList,
  getMinimizedPositionsFilteredOnList,
  getMiniplayerSizesFilteredOnList,
} from './helpers';
import { captureMessage } from '../utils/sentry';

/**
 * @private
 * Container for the private reference to BambuserLiveShoppingPlayerAPIDelegate
 */
const _delegate = new WeakMap();

/**
 * @private
 * Helper method to get the delegate for an instance
 * @param {BambuserLiveShoppingPlayerAPI} forInstance
 */
const _private = (forInstance) => _delegate.get(forInstance);

const getObjectPropertyText = (name) => `Configuration property "${name}" must be an object`;
/**
 * Bambuser Public API
 * - This is how our customers speak to our product
 * - Keep this class clean of private methods for a clear view of what we expose to the customer
 * - For other methods, see private class BambuserLiveShoppingPlayerAPIDelegate
 */
export class BambuserLiveShoppingPlayerAPI {
  /**
   * @private
   * @param {BambuserLiveShoppingPlayerAPIDelegate} delegate
   */
  constructor(delegate) {
    _delegate.set(this, delegate);
  }

  /**
   * @public
   * @enum BUTTON
   * Contains all public button behaviors
   * @return {object} enum with all public button behaviors
   * @example
   * api.configure({
   *   buttons: {
   *     dismiss: api.BUTTON.CLOSE,
   *   }
   * })
   */
  get BUTTON() {
    return getPlayerButtonBehaviorsFilteredOnList(_private(this).publicButtonBehaviors);
  }

  /**
   * @public
   * @enum EVENT
   * Contains all public events
   * @return {object} enum with all public events
   * @example
   * api.on(api.EVENT.CLOSE, () => { // do something })
   */
  get EVENT() {
    return getPlayerEventsFilteredOnList(_private(this).publicEvents);
  }

  /**
   * @public
   * @enum FLOATING_PLAYER_NAVIGATION_MODE
   * Contains all public floating player navigation modes
   * @return {object} enum with all public floating player navigation modes
   * @example
   * api.configure({
   *   floatingPlayer: {
   *     navigationMode: api.FLOATING_PLAYER_NAVIGATION_MODE.MANUAL,
   *   }
   * })
   */
  get FLOATING_PLAYER_NAVIGATION_MODE() {
    return getFloatingPlayerNavigationModesFilteredOnList(_private(this).publicFloatingPlayerNavigationModes);
  }

  /**
   * @public
   * @enum MINIMIZED_POSITION
   * Contains all public miniplayer's initial positions
   * @return {object} enum with all public miniplayer's initial positions
   * @example
   * api.configure({
   *   minimizedPosition: api.MINIMIZED_POSITION.BOTTOM_RIGHT,
   * })
   */
  get MINIMIZED_POSITION() {
    return getMinimizedPositionsFilteredOnList(_private(this).publicMinimizedPositions);
  }

  /**
   * @public
   * @enum MINIPLAYER_SIZE
   * Contains all public miniplayer's initial sizes
   * @return {object} enum with all public miniplayer's initial positions
   * @example
   * api.configure({
   *   playerSize: api.MINIPLAYER_SIZE.SMALL,
   * })
   */
  get MINIPLAYER_SIZE() {
    return getMiniplayerSizesFilteredOnList(_private(this).publicMiniplayerSizes);
  }

  /**
   * @public
   * Close the player
   * - closes the player
   * - cleans up changes to the DOM and url
   */
  close() {
    _private(this).embedInstance.destroy();
  }

  /**
   * @public
   * Minimize the player
   */
  minimize() {
    _private(this).embedInstance.minimize();
  }

  /**
   * @public
   * Retore the minimized player
   */
  unminimize() {
    _private(this).embedInstance.unminimize();
  }

  /**
   * @public
   * Configure the player
   * @param {object} config
   */
  configure(inputConfig) {
    const isValidConfigValues = (validConfigs, config) =>
      Object.values(config).every((key) => validConfigs.includes(key));
    const {
      configurableProperties,
      configurableButtons,
      configurableFloatingPlayerProperties,
      configurableUIPlayerProperties,
      configurableCookiePlayerProperties,
    } = _private(this).config;

    if (!isPlainObject(inputConfig)) throw new BambuserLiveShoppingError('Invalid configuration provided');

    const cleanConfig = (allowedConfigs, configToClean) => {
      const cleanedConfig = {};
      Object.keys(configToClean).forEach((key) => {
        if (allowedConfigs.includes(key)) {
          cleanedConfig[key] = configToClean[key];
        }
      });
      return cleanedConfig;
    };
    const config = cleanConfig(configurableProperties, inputConfig);

    if (config.hasOwnProperty('disableClickOutsideBehaviour')) {
      // TODO: Might be cool to have a map of ol' and new keys doing this magic property swap here
      config['disableClickOutsideBehavior'] = config['disableClickOutsideBehaviour'];
      delete config['disableClickOutsideBehaviour'];
      const message = 'Use of deprecated UK config name: disableClickOutsideBehaviour (in player.configure)';
      captureMessage(message);
      console.warn(message, 'Please use "disableClickOutsideBehavior" instead');

    }

    if (config.buttons) {
      if (!isPlainObject(config.buttons)) throw new BambuserLiveShoppingError(getObjectPropertyText('buttons'));
      config.buttons = cleanConfig(configurableButtons, config.buttons);

      if (!isValidConfigValues(_private(this).publicButtonBehaviors, config.buttons))
        throw new BambuserLiveShoppingError('Configuration property "buttons" contain invalid button behaviors');
    }

    if (config.experimental && !isPlainObject(config.experimental))
      throw new BambuserLiveShoppingError(getObjectPropertyText('experimental'));

    if (config.ui) {
      if (!isPlainObject(config.ui)) throw new BambuserLiveShoppingError(getObjectPropertyText('ui'));
      config.ui = cleanConfig(configurableUIPlayerProperties, config.ui);
    }

    if (config.miniPlayer) {
      if (!isPlainObject(config.miniPlayer)) throw new BambuserLiveShoppingError(getObjectPropertyText('miniPlayer'));
      if (config.miniPlayer.edgeSpacing && !isPlainObject(config.miniPlayer.edgeSpacing))
        throw new BambuserLiveShoppingError(getObjectPropertyText('miniPlayer.edgeSpacing'));
    }

    if (config.cookie) {
      if (!isPlainObject(config.cookie)) throw new BambuserLiveShoppingError(getObjectPropertyText('cookie'));
      config.cookie = cleanConfig(configurableCookiePlayerProperties, config.cookie);
    }

    if (config.floatingPlayer) {
      if (!isPlainObject(config.floatingPlayer))
        throw new BambuserLiveShoppingError(getObjectPropertyText('floatingPlayer'));
      config.floatingPlayer = cleanConfig(configurableFloatingPlayerProperties, config.floatingPlayer);

      if (
        config.floatingPlayer.hasOwnProperty('navigationMode') &&
        !_private(this).publicFloatingPlayerNavigationModes.includes(config.floatingPlayer.navigationMode)
      )
        throw new BambuserLiveShoppingError(
          'Floating player configuration property "navigationMode" has an invalid mode',
        );
    }

    if (config.minimizedPosition) {
      if (typeof config.minimizedPosition !== 'string')
        throw new BambuserLiveShoppingError('Configuration property "minimizedPosition" must be a string');

      if (!_private(this).publicMinimizedPositions.includes(config.minimizedPosition))
        throw new BambuserLiveShoppingError(
          'Floating player configuration property "minimizedPosition" has an invalid value',
        );
    }

    if (config.shareTargets) {
      if (!Array.isArray(config.shareTargets))
        throw new BambuserLiveShoppingError('Configuration property "shareTargets" must be an array');

      config.shareTargets.forEach((target) => {
        if (typeof target !== 'string')
          throw new BambuserLiveShoppingError('Configuration property "shareTargets" must be an array of strings');
      });

      try {
        config.shareTargets = JSON.stringify(config.shareTargets);
      } catch (e) {
        throw new BambuserLiveShoppingError('Configuration property "shareTargets" must be a valid JSON array');
      }
    }

    if (config.miniplayerSize) {
      if (typeof config.miniplayerSize !== 'string')
        throw new BambuserLiveShoppingError('Configuration property "miniplayerSize" must be a string');

      if (!_private(this).publicMiniplayerSizes.includes(config.miniplayerSize))
        throw new BambuserLiveShoppingError(
          'Floating player configuration property "miniplayerSize" has an invalid value',
        );
    }

    // Translate improperly formatted locales (underscore instead of dash)
    if (config.locale) config.locale = String(config.locale).replace('_', '-');

    // Last pass of cleaning
    // Remove any empty objects, no need to have them in the config
    // ... But not all, as there could be configs which are valid empty objects (or arrays)
    const notAllowedEmptyObjects = ['buttons', 'ui', 'cookie', 'floatingPlayer'];
    Object.keys(config).forEach((key) => {
      if (
        notAllowedEmptyObjects.includes(key)
        && typeof config[key] === 'object'
        && config[key] !== null
        && !Object.keys(config[key]).length
      ) delete config[key];
    });

    const { logger } = _private(this).embedInstance._embedInstance;
    logger && logger.log('configure()', config, _private(this));
    _private(this).config.setConfig(config);
  }

  /**
   * @public
   * Unsubscribe to player event
   * @param {string} eventName
   * @param {callable} handler event handler method
   */
  off(eventName, handler) {
    _private(this).off(eventName, handler);
  }

  /**
   * @public
   * Subscribe to player event
   * @param {string} eventName
   * @param {callable} handler event handler method
   */
  on(eventName, handler) {
    _private(this).on(eventName, handler);
  }

  /**
   * @public
   * Remove all event listeners
   */
  removeAllListeners() {
    // eslint-disable-next-line
    for (const eventName in _private(this).eventSubscribers) delete _private(this).eventSubscribers[eventName];
  }

  /**
   * @public
   * Show checkout page
   * @param {string} checkoutPageUrl
   * @throws {BambuserLiveShoppingError}
   */
  showCheckout(checkoutPageUrl) {
    if (!checkoutPageUrl)
      throw new BambuserLiveShoppingError('showCheckout() requires "checkoutPageUrl" to be provided as first param');
    if (typeof checkoutPageUrl !== 'string')
      throw new BambuserLiveShoppingError('showCheckout() got an invalid "checkoutPageUrl"');
    if (!checkoutPageUrl.includes('http'))
      throw new BambuserLiveShoppingError(
        'showCheckout() please specify protocol "http://" or "https://" in "checkoutPageUrl"',
      );

    _private(this).embedInstance.showCheckout(checkoutPageUrl);
  }

  /**
   * Update the cart inside the player
   * @param {object} cartData
   * @param {array} cartData.items list of id's of product in cart
   */
  updateCart(cartData) {
    const { items } = cartData;

    if (!Array.isArray(items))
      throw new BambuserLiveShoppingError('updateCart() requires cartData.items to be an array');
    if (items.length > 0)
      console.warn(
        `updateCart() currently does not support inserting cart items.
        You can only set an empty array ([]) for clearing the cart.`,
      );

    _private(this).embedInstance.updatePlayerCart({
      count: items.length,
      items,
    });
  }

  /**
   * @public
   * Update product with id
   * @param {string} productId
   * @param {callable} factory
   * @throws {BambuserLiveShoppingError}
   */
  updateProduct(productId, factory) {
    const { currency, locale } = _private(this).config.getConfig();

    if (!productId)
      throw new BambuserLiveShoppingError('updateProduct() requires first param to be a valid product id');
    if (typeof factory !== 'function')
      throw new BambuserLiveShoppingError('updateProduct() requires second param to be a function');

    const product = CreateProduct(productId);

    if (currency) product.currency(currency);
    if (locale) product.locale(locale);

    const updatedProduct = factory(product);

    if (!(updatedProduct instanceof SchemaModel))
      throw new BambuserLiveShoppingError('updateProduct() must return a valid product');

    _private(this).embedInstance.updateProducts([updatedProduct]);
  }

  /**
   * @public
   * Hide all user interfaces from the player
   */
  hideUI(uiSections) {
    _private(this).embedInstance.hideUI(uiSections);
  }

  /**
   * @public
   * Show all user interfaces of the player
   */
  showUI() {
    _private(this).embedInstance.showUI();
  }

  /**
   * @public
   * Tell players timeline to play
   */
  play() {
    _private(this).embedInstance.play();
  }

  /**
   * @public
   * Tell players timeline to pause
   */
  pause() {
    _private(this).embedInstance.pause();
  }

  /**
   * @public
   * Tell player to show the product list
   */
  showProductList() {
    return _private(this).embedInstance.showProductList();
  }

  /**
   * @public
   * Tell player to hide the product list
   */
  hideProductList() {
    return _private(this).embedInstance.hideProductList();
  }

  /**
   * @public
   * Return a promise which resolves with the set of highlighted products
   */
  getHighlightedProductsList() {
    return _private(this).embedInstance.getHighlightedProductsList();
  }

  /**
   * @public
   * Tell players timeline to seek to percent
   */
  seekToPercent(seekToPercentage) {
    _private(this).embedInstance.seekToPercent(seekToPercentage);
  }

  /**
   * @public
   * Tell players timeline to mute
   */
  mute() {
    _private(this).embedInstance.mute();
  }

  /**
   * @public
   * Tell players timeline to unmute
   */
  unmute() {
    _private(this).embedInstance.unmute();
  }

  requestPictureInPicture() {
    return _private(this).embedInstance.requestPictureInPicture();
  }

  exitPictureInPicture() {
    return _private(this).embedInstance.exitPictureInPicture();
  }

  get currentPlayerController() {
    return _private(this).embedInstance.currentPlayerController();
  }

  get playerControllers() {
    return _private(this).embedInstance.playerControllers();
  }

  externalPlayerControllerEvent(data) {
    _private(this).embedInstance.externalPlayerControllerEvent(data);
  }
}
