const _validEventIdCharactersRegex = new RegExp(/^[a-z0-9]{15,30}$/, 'i');
const _validVideoIdCharactersRegex = new RegExp(/^[a-z]{2}v_[a-z0-9]{22}$/, 'i');

/**
 * Performs a deep merge of two objects
 * @param {object} target starting object
 * @param {object} source object to merge into target recursively
 * @return {object} resulting object from merge
 */
export const deepMergeObjects = (target, source) => {
  if (!isPlainObject(target) || !isPlainObject(source)) {
    return source;
  }

  Object.entries(source).forEach(([key, sourceValue]) => {
    const targetValue = target[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      target[key] = targetValue.concat(sourceValue);
    } else if (isPlainObject(targetValue) && isPlainObject(sourceValue)) {
      target[key] = deepMergeObjects({ ...targetValue }, sourceValue);
    } else {
      target[key] = sourceValue;
    }
  });

  return target;
};

/**
 * Check if item is plain object
 * @param {any} any item to check
 * @return {boolean}
 */
export const isPlainObject = (any) => any && typeof any === 'object' && !Array.isArray(any);

/**
 * Returns the top parent node (under <body>)
 * @param {object} node in the DOM
 * @return {object} top parent node of a given node. Returns null if the given node is <body>
 */
export const getNodeTopParent = (node) => {
  if (!node?.parentNode || node === document.body || !document.body.contains(node)) return;
  while (node.parentNode !== document.body) {
    node = node.parentNode;
  }
  return node;
};

/**
 * Transforms a string from dash case to camel case
 * @param {string} text is a string in dash case
 * @return {string} a string in camel case
 */
export const dashCaseToCamelCase = (text) => {
  return text.replace(/-([a-z])/g, function (g) {
    return g[1].toUpperCase();
  });
};

/**
 * Check if eventId has a valid format, this function is not intentended
 * to be used as hard validation of an eventId
 * @param {string} eventId
 * @return {boolean}
 */
export const isValidEventIdFormat = (eventId) => {
  return eventId && typeof eventId === 'string' && (eventId === 'MOCK' || _validEventIdCharactersRegex.test(eventId));
};

/**
 * Check if eventId has a valid format, this function is not intentended
 * to be used as hard validation of an eventId
 * @param {string} videoId
 * @return {boolean}
 */
export const isValidVideoIdFormat = (videoId) => {
  return videoId && typeof videoId === 'string' && _validVideoIdCharactersRegex.test(videoId);
};

export const hasHashShowIdMatch = () => window.location.hash.match(/^#bamlvs-([a-zA-Z0-9]+)/);
