import { getIOSVersion, isAppleMobileDevice, isMacWithMultiTouch, isSafari as isSafariBrowser } from '../../../player/src/shared/utils/browser';
import Navigation from '../utils/navigation';

export const getStyles = (containerId, surfContainerId, backgroundContainerId, isDesktop) => `
  #${containerId} {
    --focus-color: #0375d8;
    --focus-style: auto;
    --focus-width: 2px;
    --focus-outline: var(--focus-color) var(--focus-style) var(--focus-width);
    background-color: rgba(0, 0, 0, 0.55);
    -webkit-animation: ${containerId}-fadein 0.3s;
    animation: ${containerId}-fadein 0.3s;
    top: unset;
    left: unset;
    display: block;
    max-width: none;
    max-height: none;
  }

  #${containerId}.is-snapping {
    transition: transform 200ms ease-out;
  }

  #${containerId} iframe,
  #${surfContainerId} iframe {
    width: 100% !important;
    height: 100% !important;
    opacity: 0 !important;
    border: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    position: relative !important;
    top: initial !important;
    left: initial !important;
    right: initial !important;
    bottom: initial !important;
    z-index: inherit !important;
  }

  #${containerId} iframe.ready {
    -webkit-animation: ${containerId}-fadein 0.3s;
    animation: ${containerId}-fadein 0.3s;
    opacity: 1 !important;
  }

  #${containerId} .livecommerce-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 52px;
    height: 52px;
    pointer-events: none;
    margin: 0;
    text-align: center;
    opacity: 0;
  }

  #${containerId} .livecommerce-loader.fade-in {
    transition: opacity 200ms;
    opacity: 1;
  }

  #${containerId} .livecommerce-loader.hidden {
    display: none;
  }

  #${containerId} .livecommerce-loader:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 52px;
    height: 52px;
    border-radius: 500rem;
    border: 1.68px solid rgba(255,255,255,.15);
  }

  #${containerId} .livecommerce-loader:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 52px;
    height: 52px;
    -webkit-animation: ${containerId}-loader-spin .6s linear;
    animation: ${containerId}-loader-spin .6s linear;
    animation-iteration-count: infinite;
    border-radius: 500rem;
    border-color: #fff transparent transparent;
    border-style: solid;
    border-width: 1.68px;
    box-shadow: 0 0 0 1px transparent
  }

  #${containerId} .livecommerce-restore-minimized,
  #${containerId} .livecommerce-close-minimized {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    transform: scale3d(1, 1, 1);
    display: none;
    opacity: 0;
    z-index: 2147483647;
    padding: ${isDesktop ? '13px 10px 19px 11px' : '9px 14px 23px 7px'};
    transform-origin: 0 0;
  }

  #${containerId} .livecommerce-close-minimized {
    left: auto;
    top: 4px;
    right: 4px;
    padding: 0;
    width: 40px;
    height: 40px;
    transform-origin: top right;
    background: rgba(0, 0, 0, 0.16);
    border-radius: 28px;
    justify-content: center;
    align-items: center;
  }

  #${containerId} .livecommerce-close-minimized.livecommerce-v2 {
    width: 48px;
    height: 48px;
    top: 0;
    right: unset;
    bottom: unset;
    left: 0;
    background: transparent;
    opacity: 1;
    display: flex;
  }

  #${containerId} .livecommerce-close-minimized.livecommerce-v2 svg {
    height: 24px;
    width: 24px;
  }

  #${containerId} .livecommerce-close-minimized.livecommerce-v2::before {
    content: ' ';
    position: absolute;
    display: block;
    top: 8px;
    left: 8px;
    width: 32px;
    height: 32px;
    background: rgba(41, 41, 41, 0.6);
    border-radius: 28px;
    z-index: -1;
  }

  #${containerId} .livecommerce-restore-minimized:focus,
  #${containerId} .livecommerce-close-minimized:focus {
    outline: none;
  }

  .${Navigation.USING_KEYBOARD} #${containerId} .livecommerce-restore-minimized:focus,
  .${Navigation.USING_KEYBOARD} #${containerId} .livecommerce-close-minimized:focus {
    outline: var(--focus-outline);
  }

  #${containerId} .livecommerce-restore-minimized path,
  #${containerId} .livecommerce-close-minimized path {
    stroke-width: 2 !important;
  }

  #${containerId} .livecommerce-restore-minimized .theming-icon {
    width: 44px;
    margin: -13px -11px -15px -6px;
    fill: #fff;
  }

  #${containerId} .livecommerce-close-minimized .theming-icon {
    width: 29px;
    margin: -6px;
    fill: #fff;
  }

  @-webkit-keyframes ${containerId}-loader-spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }

  @keyframes ${containerId}-loader-spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }

  @-webkit-keyframes ${containerId}-fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }

  @keyframes ${containerId}-fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }

  #${surfContainerId} {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    -webkit-animation: ${containerId}-fadein 0.3s;
    animation: ${containerId}-fadein 0.3s;
    opacity: 1;
  }

  #${surfContainerId} iframe {
    opacity: 1 !important;
  }

  #${backgroundContainerId} {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.55);
    pointer-events: none;
  }`;

export const getStylesInline = (containerId, height) => `
  #${containerId}.inline {
    position: relative;
    background-color: transparent;
    height: ${height};
    overflow: hidden;
  }

  #${containerId}.inline iframe {
    position: absolute;
    top: 0;
    left: 0;
  }`;

export const getIOSActivePlayerStyles = () => {
  const isSafari = isSafariBrowser();
  const iOSVersion = getIOSVersion();
  return (isAppleMobileDevice() || isMacWithMultiTouch()) &&
    `
    html {
      width: 100${isSafari && iOSVersion >= 15 ? 'vw' : '%'} !important;
      height: 100${isSafari && iOSVersion >= 15 ? 'vh' : '%'} !important;
    }
    body {
      position: fixed !important;
      min-width: 100% !important;
      min-height: 100% !important;
      max-width: 100% !important;
      max-height: 100% !important;
      margin: 0 !important;
      padding: 0 !important;
    }`;
};

export const defaultRestoreIcon = `
  <svg width='27' height='16' viewBox='0 0 27 16' fill='none' xmlns='http://www.w3.org/2000/svg' alt=''>
    <path d='M2 15L14 2L26 15' stroke='white' stroke-width='2'></path>
  </svg>`;

export const defaultCloseIcon = `
  <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg' alt=''>
    <path d='M16 16L1 1' stroke='white' stroke-width='2'></path>
    <path d='M1 16L16 1' stroke='white' stroke-width='2'></path>
  </svg>`;

export const defaultCloseIconV2 = `
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9393 12L4.96967 6.03033L6.03033 4.96967L12 10.9393L17.9697 4.96967L19.0303 6.03033L13.0607 12L19.0303 17.9697L17.9697 19.0303L12 13.0607L6.03033 19.0303L4.96967 17.9697L10.9393 12Z" fill="white"/>
  </svg>`;
