import { BAMLS, GA_MEASUREMENT_ID } from '../constants';

const isProd = process.env.TARGET_ENV === 'production';
const environment = isProd ? 'PROD' : 'STAGE';
let measurementId;
let isGAConfig = false;

export function bamlsTag() {
  window.dataLayer.push(arguments);
}

export function bamlsTagData(data) {
  return { ...data, send_to: measurementId };
}

export function initGA(withGA) {
  if (measurementId) return;
  measurementId = typeof withGA === 'string' ? withGA : GA_MEASUREMENT_ID[environment];

  const scriptGA = document.createElement('script');
  scriptGA.async = true;
  scriptGA.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${measurementId}`);
  document.head.appendChild(scriptGA);

  if (!window.dataLayer) window.dataLayer = [];
}

export function initGAConfig(userId) {
  if (!userId || isGAConfig) return;
  isGAConfig = true;
  bamlsTag('js', new Date());
  bamlsTag('config', measurementId, { user_id: userId, cookie_prefix: BAMLS });
  bamlsTag('set', 'user_properties', bamlsTagData({ bamls_usid: userId }));
}
