import Url from '../utils/url';
import { BAM_PLAYER_FIT, HORIZONTAL_POSITION, SCALE_MODE } from './constants';
import {
  getDefaultPlayerSettings,
  getSettingsString,
  parsePlayerSettings,
} from './settings';
import { getPageStyleOfTagsWithName } from './styles';

const copyStyleFromEl = (el) => el?.getAttribute('style') || '';

const applyStyleToEl = (el, style) => el?.setAttribute('style', style || '');

const { fetch, setTimeout, clearTimeout } = window;

const DEFAULT_LIMIT = 20; // Also is the maximum number of players that can be rendered
const AUTOPLAY_CASCADE_PLAY_DURATION_MS = 4000; // Time to wait before starting the next player in the autoplay cascade
const UNLOAD_PLAYER_UI_DELAY_MS = 0; // Time to wait before unloading the player UI after it has been hidden

const discoverMode = Url.isParamTrue('bambuserVodDiscover');

const cachedFetchPromises = {};
const cacheTime = 10 * 1000; // 10 seconds
const cachedFnCall = (id, fn) => {
  const cached = (() => {
    const _cached = cachedFetchPromises[id];
    if (!_cached) return null;
    if (Date.now() - _cached.time > cacheTime) {
      delete cachedFetchPromises[id];
      return null;
    }
    return _cached;
  })();

  if (!cached) {
    cachedFetchPromises[id] = {
      time: Date.now(),
      res: fn(),
    };
  }

  return cachedFetchPromises[id].res;
};

const loadPlaylist = async ({ videosDataSource, eventIds, channelId, videoIds, orgId, query, containerId, cursor }) => {
  let playlist = [];
  let playlistMetadata = [];
  let playerConfig = {};
  let distributionPageId = null;
  let distributionContainerId = null;
  let nextCursor = null;

  if (videosDataSource === 'shows' && Array.isArray(eventIds) && eventIds.length > 0) {
    playlist = eventIds;
  } else if (videosDataSource === 'vod' && Array.isArray(videoIds) && videoIds.length > 0) {
    playlist = videoIds;
  } else if (videosDataSource === 'shows' && channelId) {
    // Get content from a Channel
    const cacheKey = `channel-${channelId}`;
    const { playlists } = await cachedFnCall(cacheKey, () => fetch(`${process.env.WIDGETS_APPENGINE_BASE_URL}/channels/${channelId}`)
      .then((response) => response.json())
      .then((json) => json || {}));

    for (const { shows } of playlists) {
      if (!Array.isArray(shows)) continue;
      for (const { showId, isLive, isArchived, duration, isTestShow } of shows) {
        if (!showId || !(isLive || isArchived) || duration <= 0 || isTestShow) continue;
        if (playlist.includes(showId)) continue;
        playlist.push(showId);
      }
    }
  } else if (videosDataSource === 'vod' && orgId) {
    const { productReference: sku } = findPageProperties();

    const url = getUrl();
    const title = document.title;
    const availableParams = {
      // Component defined data
      orgId,
      query,
      containerId,
      // Additional data from page
      url,
      title,
      ...(sku && { sku }),
      ...(cursor && { cursor }),
    };

    const params = new URLSearchParams();
    for (const [key, value] of Object.entries(availableParams)) {
      if (value) params.append(key, value);
    }

    const cacheKey = `org-${orgId}-${params.toString()}`;
    const { data, pagination } = await cachedFnCall(cacheKey, () => fetch(`${process.env.WIDGETS_APPENGINE_BASE_URL}/distribution${params ? `?${params.toString()}` : ''}`)
      .then((response) => response.json())
      .then((json) => json || {}));
    nextCursor = pagination?.cursor || null;
    playlist = data?.playlist || [];
    playlistMetadata = data?.playlistMetadata || [];
    playerConfig = data?.playerConfig || {};
    distributionPageId = data?.pageId;
    distributionContainerId = data?.id;
  }

  return {
    nextCursor,
    playlist,
    playlistMetadata,
    playerConfig,
    distributionPageId,
    distributionContainerId,
  };
};

const findSuggestShopifyProperities = () => {
  const shopifyProductProps = {};
  const productIdHiddenInputRef = document.querySelector('input[name="product-id"][type="hidden"]');
  if (productIdHiddenInputRef?.value) {
    shopifyProductProps['productReference'] = productIdHiddenInputRef.value;
  }

  return shopifyProductProps;
};


const findSuggestLdJsonProductProperities = () => {
  const ldJsonProductProps = {};
  const scriptElements = document.querySelectorAll('script[type="application/ld+json"]');

  for (const scriptElement of scriptElements) {
    // Iterate through the script elements to find the one containing the product schema
    try {
      const jsonData = JSON.parse(scriptElement.textContent);

      // Check if the JSON-LD contains a Product type
      if (jsonData['@type'] === 'Product') {
        for (const [key, value] of Object.entries(jsonData)) {
          if (!ldJsonProductProps[key]) ldJsonProductProps[key] = value;
        }
      }
    } catch (error) {
      console.error('Error parsing JSON-LD', error);
    }
  }

  return ldJsonProductProps;
};

const findSuggestMicrodataProductProperities = () => {
  const microdataProductProps = {};
  const productMetaContainer = document.querySelector('[itemtype="http://schema.org/Product"]');

  if (productMetaContainer) {
    const itempropElements = productMetaContainer.querySelectorAll('[itemprop]');

    for (const itempropElement of itempropElements) {
      const propName = itempropElement.getAttribute('itemprop');
      const propValue = itempropElement.innerText;

      if (!microdataProductProps[propName]) microdataProductProps[propName] = propValue;
    }
  }

  return microdataProductProps;
};

const enrichObjectWithExpecteProductPropsMapping = (data) => {
  const productIdPropPriority = [
    'sku',
    'gtin',
    'gtin8',
    'gtin12',
    'gtin13',
    'gtin14',
    'productID',
  ];

  for (const prop of productIdPropPriority) {
    if (data.productReference) break;
    if (data[prop]) {
      data.productReference = data[prop];
      break;
    }
  }

  return data;
};

const getUrl = () => {
  // Try to get the URL from the canonical link element
  const canonicalLink = document.querySelector('link[rel="canonical"]');
  if (canonicalLink && canonicalLink.href) {
    return canonicalLink.href;
  }

  // Try to get the URL from the Open Graph meta tag
  const ogUrlMeta = document.querySelector('meta[property="og:url"]');
  if (ogUrlMeta && ogUrlMeta.content) {
    return ogUrlMeta.content;
  }

  // Fallback to the current page's URL
  return window.location.href;
};

const findPageProperties = () => {
  const res = {
    productReference: null,
  };

  try {
    const dataPagePropertiesFinder = [
      () => findSuggestShopifyProperities(),
      () => enrichObjectWithExpecteProductPropsMapping(findSuggestLdJsonProductProperities()),
      () => enrichObjectWithExpecteProductPropsMapping(findSuggestMicrodataProductProperities()),
    ];

    for (const dataFinder of dataPagePropertiesFinder) {
      const data = dataFinder();
      // Fill the "res" object with values from the first data provider that has something
      for (const key of Object.keys(res)) {
        if (!res[key] && data[key]) {
          // This property is still empty in the result object: use the value from the data provider
          res[key] = data[key];
        }
      }
      // No need to continue looking for data in next data
      // finder if we found all data we're looking for
      if (Object.values(res).every(Boolean)) break;
    }
  } catch (error) {
    // nothing to do really
  }

  return res;
};

const scrollToElement = (container, element) => {
  if (container && element) {
    const playerWidthPx = parseFloat(window.getComputedStyle(element).getPropertyValue('width'));
    const paddingOffset = Math.ceil(0.03 * playerWidthPx);
    const scrollPosition = element.offsetLeft - container.offsetLeft - paddingOffset;
    const currentScrollPosition = container.scrollLeft;

    // Scroll smoothly to the calculated position, only if we would scroll forwards
    if (currentScrollPosition < scrollPosition) {
      container.scrollTo({
        left: scrollPosition,
        behavior: 'smooth'
      });
    }
  }
};

const resetStyleProperty = (currentStyle, originalStyle, propertyName) => {
  if (currentStyle[propertyName]) {
    if (originalStyle[propertyName]) {
      currentStyle[propertyName] = originalStyle[propertyName];
    } else {
      currentStyle[propertyName] = '';
    }
  };
};

const throttle = (func, limit) => {
  let inThrottle;
  return function(...args) {
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => inThrottle = false, limit);
    }
  };
};

/**
 * A custom element that bundles multiple bam-player elements into a group.
 *
 * The component will automatically load a playlist of shows from a channel or a list of event IDs.
 * It will then render a grid of bam-player elements, each representing a show in the playlist.
 * The user can click on a player to expand it and focus on it, or hover over it to start playing it.
 *
 * There are multiple display modes, both for the initial thing that is rendered on the embed page,
 * and for the expanded view which is shown when the user clicks on a player.
 */
export default class BambuserBundleComponent extends window.HTMLElement {
  // Changes in these attributes will trigger attributeChangedCallback()
  static observedAttributes = [
    'channel-id',
    'event-ids',
    'mode', // row | grid (default) | single
    'focus-mode', // carousel (default) | single | playpause | none
    'autoplay', // hover (default) | cascade
    'limit', // [0, N] - max number of players to render
    'video-ids', // comma-separated list of video ids
    'org-id', // the expected org id for the bam-bundle component
    'query', // the query to use to fetch videos for defined org
    'playlist-id', // the id of the bam-playlist component
    'player-settings', // settings for the players
    'zoom-on-hover', // zoom players on hover (true | false)
    'player-fit', // player fit for the bundle (same-width | same-height | exact-size | fill-parent)

    // TODO?
    // 'captions', on | off
    // 'size', // size of bam-player
  ];

  constructor() {
    super();
    this.shadow = this.attachShadow({ mode: 'open' });

    // TODO: This works poorly because the player iframes will capture keyboard events
    document.addEventListener('keyup', (event) => {
      if (event.key === 'Escape') {
        this._contract();
      }
    });

    this._playerSettings = getDefaultPlayerSettings();
    this._remotePlayerConfig = {};
    this._playerVisibilityMap = {};
    this._isExpanded = false;
  }

  /** Standard Web Component methods */

  connectedCallback() {
    if (this._hasConnected) return;
    this._hasConnected = true;
    this._setup();
  }

  disconnectedCallback() {
    this._updateStylesheet();
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (!this._hasConnected) {
      // Do nothing if the component hasn't been connected to the DOM yet
      return;
    }

    if (oldValue === newValue) return;

    // The following attributes should trigger a full re-render
    if ([
      'channel-id',
      'event-ids',
      'focus-mode',
      'autoplay',
      'limit',
      'video-ids',
      'org-id',
      'query',
      'playlist-id',
    ].includes(name)) {
      this._setup();
      return;
    }

    if (name === 'player-settings') {
      this._updatePlayersSettingsFromAttributes();
      this._applyPlayerSettingsUpdate();
      this._updateStylesheet();
    }

    if (name === 'mode') {
      this._updateStylesheet();
    }
  }

  /** Custom methods */

  get channelId() {
    return this.getAttribute('channel-id');
  }

  get eventIds() {
    return (this.getAttribute('event-ids') || '').split(',').map(it => it.trim()).filter(Boolean);
  }

  get videoIds() {
    return (this.getAttribute('video-ids') || '').split(',').map(it => it.trim()).filter(Boolean);
  }

  get orgId() {
    return this.getAttribute('org-id');
  }

  get _videosDataSource() {
    const { eventIds, videoIds, channelId, orgId } = this;
    if (Array.isArray(eventIds) && eventIds.length > 0) {
      return 'shows';
    } else if (Array.isArray(videoIds) && videoIds.length > 0) {
      return 'vod';
    } else if (channelId) {
      return 'shows';
    } else if (orgId) {
      return 'vod';
    }
    return null;
  }

  get _attr() {
    const { orgId, channelId, eventIds, videoIds, _videosDataSource: videosDataSource } = this;
    // Range of possible values is integer in [0, DEFAULT_LIMIT]
    const providedLimit = parseInt(this.getAttribute('limit'), 10);
    const limit = Number.isSafeInteger(providedLimit)
      ? Math.max(0, Math.min(parseInt(providedLimit, 10), DEFAULT_LIMIT))
      : DEFAULT_LIMIT;

    // In the case that there are multiple bam-bundle elements on the page, we need to
    // somehow identify them, and chances are that the developer implementing the component
    // gave the elements an id which we can use to identify a specific component so we can distribute
    // and render the expected videos in the component.
    const nodeListArray = [
      ...Array.prototype.slice.call(document.querySelectorAll('bam-bundle')), // legacy, to be removed
      ...Array.prototype.slice.call(document.querySelectorAll('bam-playlist'))
    ].filter(component => {
      if (component === this) return true;

      // Scoping the incremented ids per video data source type (ex. vod or shows)
      if (component._videosDataSource !== videosDataSource) return false;

      // For vod its futher scoped per org id
      if (videosDataSource === 'vod' && component.orgId !== orgId) return false;

      return true;
    });
    const domNodeIndex = nodeListArray.indexOf(this);
    const autoIndexId = domNodeIndex >= 0 ? `playlist-auto-id-${domNodeIndex}` : null;

    const { productCardMode, title, overlayTextWrap } = this._remotePlayerConfig || {};

    const remotePlayerSettingsOverrides = {
      ...(productCardMode && { productCardMode }),
      ...(title && { title }),
      ...(overlayTextWrap && { overlayTextWrap }),
    };
    const remotePlayerSettingsOverridesString = getSettingsString(remotePlayerSettingsOverrides);

    const zoomOnHover = this.getAttribute('zoom-on-hover') !== null ?
      this.getAttribute('zoom-on-hover') === true :
      (this._remotePlayerConfig?.zoomOnHover || false);

    return {
      videosDataSource,
      channelId,
      eventIds,
      mode: this.getAttribute('mode') || this._remotePlayerConfig?.mode || 'grid',
      focusMode: this.getAttribute('focus-mode') || this._remotePlayerConfig?.focusMode || 'carousel',
      autoplay: this.getAttribute('autoplay') || this._remotePlayerConfig?.autoplay || 'hover',
      videoIds,
      orgId,
      query: this.getAttribute('query') || 'auto',
      containerId: this.getAttribute('playlist-id') || autoIndexId,
      playerSettings: this.getAttribute('player-settings') || remotePlayerSettingsOverridesString,
      zoomOnHover,
      limit,
      playerFit: this.getAttribute('player-fit') || BAM_PLAYER_FIT.SAME_WIDTH,
      landscapeInPortrait: this.getAttribute('landscape-in-portrait') ||
        this._remotePlayerConfig?.landscapeInPortrait ||
        SCALE_MODE.FIT,
    };
  }

  _updateStylesheet(playerRef) {
    if (!this._styleSheet) return;

    const { zoomOnHover } = this._attr;

    const {
      thumbnailSize = '68px',
      cornerRadius = '0px',
      playerWidth,
      playerHeight,
      playerPlaceholderColor = '#EEE',
      playerOverlayFontFamily = 'sans-serif',
      playerOverlayFontWeight = '700',
      playlistGap = '25px',
      playlistCarouselCornerRadius = '0px',
    } = this._remotePlayerConfig || {};

    const defaultPlayerWidth = playerWidth || '250px';
    const defaultPlayerHeight = playerHeight || '444px';

    let playerFitStyles = '';

    switch (this._attr.playerFit) {
      case BAM_PLAYER_FIT.FILL_PARENT:
        playerFitStyles = `
          .wrapper:not(.is-expanded) bam-player {
            width: 100%;
            max-width: 100%;
            height: 100%;
            max-height: 100%;
          }
        `;
        break;
      case BAM_PLAYER_FIT.EXACT_SIZE:
        playerFitStyles = `
          .wrapper:not(.is-expanded) bam-player {
            width: var(--bam-player-width, ${defaultPlayerWidth});
            max-width: var(--bam-player-width, ${defaultPlayerWidth});
            height: var(--bam-player-height, ${defaultPlayerHeight});
            max-height: var(--bam-player-height, ${defaultPlayerHeight});
          }
        `;
        break;
      case BAM_PLAYER_FIT.SAME_HEIGHT:
        playerFitStyles = `
          .wrapper:not(.is-expanded) bam-player {
            height: var(--bam-player-height, ${defaultPlayerHeight});
            max-height: var(--bam-player-height, ${defaultPlayerHeight});
          }
        `;
        break;
      case BAM_PLAYER_FIT.SAME_WIDTH:
      default:
        playerFitStyles = `
          .wrapper:not(.is-expanded) bam-player {
            width: var(--bam-player-width, ${defaultPlayerWidth});
            max-width: var(--bam-player-width, ${defaultPlayerWidth});
          }
        `;
        break;
    }


    let modeStyles = '';
    switch (this._attr.mode) {
      case 'row':
        modeStyles = `
          :host {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
          }
          ${playerFitStyles}
          .wrapper:not(.is-expanded) .player-container {
            overflow-x: scroll;

            -ms-overflow-style: none;
            scrollbar-width: none;
          }
          .wrapper:not(.is-expanded) .player-container::-webkit-scrollbar {
            display: none;
          }
        `;
        break;
      case 'single':
        const hostStyles = this._attr.playerFit === BAM_PLAYER_FIT.FILL_PARENT ? `
          :host,
          .wrapper:not(.is-expanded),
          .wrapper:not(.is-expanded) .player-container,
          .wrapper:not(.is-expanded) .player-container .player-wrapper {
            width: 100%;
            height: 100%;
          }
          ` : `
          :host {
            width: fit-content;            
          }`;

        modeStyles = `
          ${hostStyles}
          ${playerFitStyles}
          .wrapper:not(.is-expanded) .player-container {
            display: flex;
          }
          .wrapper:not(.is-expanded) .player-container .player-wrapper:not(:first-child) {
            display: none;
          }
        `;
        break;
      case 'grid':
      default:
        modeStyles = `
          .player-container {
            flex-wrap: wrap;
          }
          .wrapper.is-expanded .player-container {
            display: flex;
            flex-wrap: nowrap;
          }
          ${playerFitStyles}
        `;
        break;
    }

    const shouldZoomOnHover = !this._isExpanded && zoomOnHover;

    let autoDetectedFontFamily;
    const h1FontFamily = getPageStyleOfTagsWithName('h1', 'fontFamily');
    if (h1FontFamily) {
      autoDetectedFontFamily = h1FontFamily;
    }

    this._styleSheet.textContent = `
      :host {
        display: block;
        ${thumbnailSize ? `--bam-player-thumbnail-size: ${thumbnailSize};` : ''}
        ${autoDetectedFontFamily ? `--bam-player-auto-detected-overlay-font-family: ${autoDetectedFontFamily}` : ''};
        --internal-font-family: var(--bam-player-overlay-font-family, var(--bam-player-auto-detected-overlay-font-family, ${playerOverlayFontFamily}));
        --internal-corner-radius: ${cornerRadius};
        ${playerWidth ? `--internal-width: ${playerWidth};` : ''}
        ${playerHeight ? `--internal-height: ${playerHeight};` : ''}
        --internal-overlay-font-weight: ${playerOverlayFontWeight};
        --internal-placeholder-color: ${playerPlaceholderColor};
      }
      .wrapper {
        width: 100%;
      }
      .player-container {
        display: flex;
      }
      .player-wrapper {
        display: inline-block;
      }
      .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 10px;
        z-index: 10000001;
        cursor: pointer;
        display: none;
        color: white;
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        font-size: 32px;
        opacity: 0.4;
      }
      .load-more-button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        position: relative;
        overflow: hidden;
        overscroll-behavior: none;
        font-weight: var(--bam-player-overlay-font-weight, ${playerOverlayFontWeight});
        border-radius: var(--bam-player-corner-radius, ${cornerRadius});
        font-family: var(--internal-font-family), Helvetica, Arial, Verdana, Tahoma, sans-serif;
        cursor: pointer;
        color: black;
        flex-shrink: 0;
      }
      .close-button:hover,
      .load-more-button:hover {
        opacity: 0.8;
      }
      .wrapper:not(.is-expanded) .player-container {
        gap: var(--bam-playlist-gap, var(--bam-bundle-gap, ${playlistGap}));
      }
      .wrapper:not(.is-expanded) bam-player {
        cursor: pointer;
        z-index: 1;
      }
      ${!shouldZoomOnHover ? '' : `
        .wrapper:not(.is-expanded) .player-container {
          padding: 50px 0;
          padding-left: calc(0.03 * var(--bam-player-width, ${defaultPlayerWidth}));
          padding-left: calc(round(up, 0.03 * var(--bam-player-width, ${defaultPlayerWidth}), 1px));
          padding-right: calc(0.03 * var(--bam-player-width, ${defaultPlayerWidth}));
          padding-right: calc(round(up, 0.03 * var(--bam-player-width, ${defaultPlayerWidth}), 1px));
        }
        .wrapper:not(.is-expanded) .player-wrapper {
          transition: transform 300ms ease;
        }
        .wrapper:not(.is-expanded) .player-wrapper:hover {
          z-index: 10;
          transform: scale(1.06);
        }
        .wrapper:not(.is-expanded) .player-wrapper:hover bam-player {
          transition: box-shadow 300ms ease-in;
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
        }
      `}
      .wrapper.is-expanded {
        z-index: 10000000;
        inset: 0px;
        position: fixed;
        height: 100%;
      }
      .wrapper.is-expanded .player-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: flex-start;
        height: 100%;

        min-height: 80vh;
        scroll-snap-align: center;
        scroll-snap-stop: always;
      }
      .wrapper.is-expanded .dimmer {
        position: absolute;
        inset: 0px;
        background-color: rgba(0, 0, 0, 1);
        z-index: -1;
      }
      .wrapper.is-expanded .player-container {
        white-space: nowrap;
        position: absolute;
        height: 100%;
        column-gap: 25px;

        box-sizing: border-box;
        max-height: 100vh;
        padding-top: 50vh;
        padding-bottom: 50vh;
        overflow: auto;
        scroll-snap-type: both mandatory;
        overscroll-behavior: contain;
      }
      .wrapper.is-expanded .close-button {
        display: block;
      }
      .wrapper.is-expanded .player-wrapper:not(.focused) {
        opacity: 0.2;
        transition: opacity 0.5s;
      }
      .wrapper.is-expanded .player-wrapper:not(.focused) bam-player {
        cursor: pointer;
      }
      /* touch devices will not have hover */
      @media (orientation: portrait) and (hover: none) {
        .wrapper.is-expanded {
          justify-content: flex-start;
        }
        .wrapper.is-expanded .player-container {
          width: 100%;
          flex-direction: column;
          height: auto;
          height: calc(var(--vh, 1vh) * 100);

          min-height: 100vh;
          min-height: calc(var(--vh, 1vh) * 100);

          padding-top: unset;
          padding-bottom: unset;
        }
        .wrapper.is-expanded .player-wrapper {
          width: 100%;
          inset: 0px;
          margin: 0;
          height: 100vh; /* Fallback for browsers that do not support custom css properties */
          height: calc(var(--vh, 1vh) * 100);

          min-height: 100vh;
          min-height: calc(var(--vh, 1vh) * 100);
        }
        .wrapper.is-expanded bam-player {
          border-radius: 0;
          inset: 0px;
          width: 100%;
          height: 100%;
          max-width: none;
          --bam-player-placeholder-color: #000;
        }
      }
      /* non-touch devices will have hover */
      @media (orientation: portrait) and (hover: hover) {
        .wrapper.is-expanded .player-container {
          height: auto;
          width: 100%;
          flex-direction: column;
          row-gap: 25px;
        }
        .wrapper.is-expanded .player-wrapper {
          height: 80vh;
          align-items: center;
        }
        .wrapper.is-expanded bam-player {
          max-height: 80vh;
          max-width: 90vw;
          height: 100%;
          width: auto;
          border-radius: var(--bam-playlist-carousel-corner-radius, var(--bam-bundle-carousel-corner-radius, ${playlistCarouselCornerRadius}));
          --bam-player-placeholder-color: transparent;
        }
      }
      @media (orientation: landscape) {
        .player-container {
          flex-direction: row;
        }

        .wrapper.is-expanded .player-container {
          max-width: 100vw;

          padding-left: 50vw;
          padding-right: 50vw;

          overflow-y: hidden;
        }       

        .wrapper.is-expanded bam-player {
          max-height: 80vh;
          max-width: 80vw;
          height: 100%;
          width: auto;

          /*
            If a player is slimmer than this and expected to present interactive UI the player is expected
            to be presented in an accessibility type of way, and the player will apply a overflow scroll type
            which lets the user scroll around in the player to see all elements, which is not what we want when
            presented in a carousel type of way.
          */
          min-width: 320px;
          border-radius: var(--bam-playlist-carousel-corner-radius, var(--bam-bundle-carousel-corner-radius, 20px));

          --bam-player-placeholder-color: transparent;
        }
        .wrapper.is-expanded .load-more-button {
          color: white;
        }
      }
      ${modeStyles}
      ${(discoverMode && this._videosDataSource === 'vod') ? `
      :host {
        border: 2px solid #7D58EE;
      }
      .wrapper {
        position: relative;
      }
      .discover-meta {
        color: #7D58EE;
        position: absolute;
        top: 4px;
        left: 4px;
      }
      ` : ''}
    `;


    // If playerMode is 'single' we need to make sure we handle the z space correctly for the players
    // to make sure the relevant player is on top when expanded, and the other ones are places underneath
    if (this._attr.focusMode === 'single' && this._players) {
      for (const player of this._players) {
        if (player === playerRef) {
          player.style.zIndex = 1;
        } else {
          player.style.zIndex = 0;
        }
      }
    }
  }

  _bumpVhCSSVariable() {
    const vh = window.innerHeight * 0.01;
    this._wrapper.style.setProperty('--vh', `${vh}px`);
  }

  _applyPlayerSettingsUpdate() {
    const { playerSettings: playerSettingsAttributeValue } = this._attr;
    if (!playerSettingsAttributeValue) return;
    if (!this._players?.length) return;

    this._players.forEach((player) => {
      player.setAttribute('settings', playerSettingsAttributeValue);
    });
  }

  _updatePlayersSettingsFromAttributes() {
    const { playerSettings: playerSettingsAttributeValue } = this._attr;
    if (!playerSettingsAttributeValue) return;

    this._playerSettings = { ...this._playerSettings, ...parsePlayerSettings(playerSettingsAttributeValue)};
  };

  async _setup() {
    // eslint-disable-next-line no-multi-assign
    const setupId = this._setupIdIndex = (this._setupIdIndex || 0) + 1;
    this._lastSetupId = setupId;

    let playlist = [];
    let playlistMetadata = [];
    try {
      const {
        playlist: actualPlaylist,
        playlistMetadata: actualPlaylistMetadata,
        playerConfig: actualPlayerConfig,
        distributionPageId,
        distributionContainerId,
        nextCursor,
      } = await loadPlaylist(this._attr);

      playlist = actualPlaylist;
      playlistMetadata = actualPlaylistMetadata;
      this._remotePlayerConfig = actualPlayerConfig;
      this._distributionPageId = distributionPageId;
      this._distributionContainerId = distributionContainerId;
      this._nextPaginationCursor = nextCursor;
    } catch (err) {
      console.error('Failed to load playlist', err);
    }
    if (this._lastSetupId !== setupId) {
      // Another setup has been started since this one, so let's return and allow the newer setup to take over.
      return;
    }

    const { focusMode, autoplay, limit, playerFit, mode, landscapeInPortrait } = this._attr;

    if (!['carousel', 'single', 'playpause', 'none'].includes(focusMode)) {
      console.error('Invalid "focusMode" attribute. Must be either "carousel", "single", "playpause" or "none".');
      return;
    }

    if (!['cascade', 'hover', 'none'].includes(autoplay)) {
      console.error('Invalid "autoplay" attribute. Must be either "cascade" or "hover" or "none".');
      return;
    }

    if (!Object.values(BAM_PLAYER_FIT).includes(playerFit)) {
      console.error(`Invalid "playerFit" attribute. Must be either ${Object.values(BAM_PLAYER_FIT).join(" or ")}.`);
      return;
    }

    if (playerFit === BAM_PLAYER_FIT.FILL_PARENT && !(limit === 1 || mode === 'single')) {
      console.error('The "fill-parent" player fit mode can only be used with a limit of 1 or mode "single".');
      return;
    }

    if (limit === 0) {
      console.info('The limit is set to 0, so no players will be rendered.');
      return;
    }

    // Make sure we start from a clean slate
    this.shadow.innerHTML = '';
    this._stopAutoplayCascade();

    if (!playlist.length && !discoverMode) return;

    this._updatePlayersSettingsFromAttributes();

    // Setup DOM elements
    const styleSheet = document.createElement('style');
    this._styleSheet = styleSheet;

    const wrapper = document.createElement('div');
    wrapper.classList.add('wrapper');
    this._wrapper = wrapper;

    const dimmer = document.createElement('div');
    dimmer.classList.add('dimmer');

    const playerContainer = document.createElement('div');
    playerContainer.classList.add('player-container');
    this._playerContainer = playerContainer;

    const closeButton = document.createElement('div');
    closeButton.classList.add('close-button');
    closeButton.textContent = '✕';
    closeButton.addEventListener('click', () => this._contract());

    this.shadow.appendChild(styleSheet);
    this.shadow.appendChild(wrapper);
    wrapper.appendChild(dimmer);
    wrapper.appendChild(closeButton);
    wrapper.appendChild(playerContainer);
    if (this._videosDataSource === 'vod') {
      // If we have a next pagination cursor and have yet to reach the player limit, we can load more videos
      if (this._nextPaginationCursor && playlist.length < limit) {
        const loadMoreButton = document.createElement('div');
        loadMoreButton.classList.add('load-more-button');
        loadMoreButton.textContent = 'Load more';
        loadMoreButton.addEventListener('click', this._loadNextPaginationCursor);
        loadMoreButton.style.order = Number.MAX_SAFE_INTEGER;
        playerContainer.appendChild(loadMoreButton);
        this._loadMoreButton = loadMoreButton;
      }

      if (discoverMode) {
        const discoverMeta = document.createElement('div');
        discoverMeta.classList.add('discover-meta');
        discoverMeta.textContent = `${this._attr.containerId}`;
        wrapper.appendChild(discoverMeta);
      }
    }

    this._updateStylesheet();
    this._determineScrollDirection();

    this._playerContainer.addEventListener('scroll', throttle((e) => this._onScroll(e), 50), { passive: true });
    if ('onscrollend' in window) {
      this._playerContainer.addEventListener('scrollend', () => this._onScrollEnd(), { passive: true });
    }

    // Create a bam-player for each show in the playlist
    this._players = playlist.slice(0, limit).map((eventId) => this._createBamPlayer({
      eventId,
      focusMode,
      autoplay,
      isSingleVideo: playlist.length === 1,
      playerFit,
      mediaAssets: playlistMetadata?.find(videoMetadata => videoMetadata.id === eventId)?.mediaAssets,
      landscapeInPortrait,
    }));

    // Set up autoplay in cascade mode, meaning we kick off the first player in the series,
    // then after a while we start the next one, and so on.
    this._startAutoplayCascade();

    // React to changes in the viewport size
    this._bumpVhCSSVariable();
    window.addEventListener('resize', () => {
      this._bumpVhCSSVariable();
      this._determineScrollDirection();
      this._scrollToPlayer(this._focusedPlayer, true, false);
    });
  }

  _overrideAncestorsStyles() {
    let el = this;
    this.ancestorStyleInfo = new Map();
    while (el !== document.body) {
      const inlineStyle = el.style;
      const computedStyle = window.getComputedStyle(el);
      this.ancestorStyleInfo.set(el, {
        position: inlineStyle.position,
        zIndex: inlineStyle.zIndex,
        transform: inlineStyle.transform,
      });
      if (computedStyle.position === 'static') {
        el.style.position = 'relative';
      }
      if (computedStyle.transform !== 'none') {
        el.style.transform = 'none';
      }
      el.style.zIndex = 2147483647;
      el = el.parentNode;
    }
  }

  _revertAncestorsStyles() {
    this.ancestorStyleInfo.forEach((inlineStyle, el) => {
      const currentInlineStyle = el.style;
      ['position', 'zIndex', 'transform'].forEach((propertyName) => {
        resetStyleProperty(currentInlineStyle, inlineStyle, propertyName);
      });
    });
  }

  _createBamPlayer = ({
    eventId,
    focusMode,
    autoplay,
    isSingleVideo,
    playerFit,
    mediaAssets,
    landscapeInPortrait,
  }) => {

    const bamPlayer = document.createElement('bam-player');

    if (mediaAssets) {
      bamPlayer.setMediaAssets(mediaAssets);
    }

    if (this._distributionPageId) {
      bamPlayer.setAttribute("distribution-page-id", this._distributionPageId);
    }

    if (landscapeInPortrait) {
      bamPlayer.setAttribute("landscape-in-portrait", landscapeInPortrait);
    }

    if (this._distributionContainerId) {
      bamPlayer.setAttribute(
        "distribution-container-id",
        this._distributionContainerId
      );
    }

    // Pass player settings to the player
    bamPlayer.setAttribute('settings', getSettingsString(this._playerSettings));

    if (this._videosDataSource === 'vod') {
      bamPlayer.setAttribute('video-id', eventId);
    } else {
      bamPlayer.setAttribute('event-id', eventId);
    }

    bamPlayer.setAttribute('player-fit', playerFit);

    if (autoplay === 'hover') {
      bamPlayer.setAttribute('autoplay', 'hover');
    } else if (autoplay === 'cascade') {
      // We'll handle autoplay ourselves
      const onMouseEvent = (event) => {
        if (this._isExpanded) return;
        if (event.type === 'mouseenter') {
          this._stopAutoplayCascade();
          bamPlayer.play();
        } else {
          this._startAutoplayCascade(bamPlayer);
        }
      };
      // Only necessary when there are more than one player
      if (!isSingleVideo) {
        bamPlayer.addEventListener('mouseenter', onMouseEvent);
        bamPlayer.addEventListener('mouseleave', onMouseEvent);
      }
    } else if (autoplay === 'none') {
      // do nothing, autoplay is disabled
    }

    const triggerClickPlayerEvent = () => {
      const detailEventIdKey = this._videosDataSource === 'vod' ? 'videoId' : 'eventId';
      const detail = {
        [detailEventIdKey]: eventId,
      };
      this.dispatchEvent(new CustomEvent('clickplayer', { detail }));
    };

    // Set up everything needed for the carousel mode
    if (focusMode === 'carousel') {
      bamPlayer.setAttribute('standalone', 'false'); // We'll handle the expansion ourselves
      bamPlayer.addEventListener('close', () => this._contract());

      if (!isSingleVideo) {
        bamPlayer.addEventListener('show-product', () => {
          if (this._isExpanded && this._scrollDirection === 'vertical') {
            this._blockScroll();
          }
        });

        bamPlayer.addEventListener('hide-product', () => {
          this._unblockScroll();
        });
      }

      // Auto-advance to the next player when the current one ends
      bamPlayer.addEventListener('ended', () => {
        if (this._focusedPlayer === bamPlayer && !this._focusedPlayer.hasImportantUIShown()) {
          this._next();
        }
      });

      // Clicking on a player should enter carousel mode and focus on the player that was clicked
      bamPlayer.addEventListener('click', () => {
        const wasExpanded = this._isExpanded;
        this._expand();

        if (!wasExpanded) {
          // For the case when no scrolling is needed - clicking on the first player
          // in the initial position
          this._onScroll();
        }

        this._scrollToPlayer(bamPlayer, wasExpanded);

        // Special case when we are using native scrollend event
        // And we don't actually scroll the player into view
        // We need to trigger the scrollend event manually
        if(!wasExpanded && ('onscrollend' in window)) {
          this._onScrollEnd();
        }

        triggerClickPlayerEvent();
      });
    }

    if (focusMode === 'single') {
      bamPlayer.addEventListener('click', () => {
        bamPlayer._onClick();
        this._isExpanded = true;
        this._updateStylesheet(bamPlayer);
        this._overrideAncestorsStyles();
        triggerClickPlayerEvent();
      });
      bamPlayer.addEventListener('close', () => {
        this._isExpanded = false;
        this._updateStylesheet(bamPlayer);
        this._revertAncestorsStyles();
      });
    }

    if (focusMode === 'playpause') {
      bamPlayer.setAttribute('standalone', 'false');
      bamPlayer.addEventListener('click', () => {
        if(bamPlayer._isPlaying) {
          bamPlayer.pause();
        } else {
          bamPlayer.play();
        }
        triggerClickPlayerEvent();
      });
    }

    if (focusMode === 'none') {
      bamPlayer.addEventListener('click', (e) => {
        e.stopPropagation();
        triggerClickPlayerEvent();
      }, { capture: true });
    }

    // Set player loop setting
    this._remotePlayerConfig?.loop && bamPlayer.setAttribute('loop', 'true');

    // Pass show-product setting to the player
    this._remotePlayerConfig?.showProducts !== undefined
      ? bamPlayer.setAttribute('show-products', this._remotePlayerConfig.showProducts)
      : bamPlayer.removeAttribute('show-products');

    // Wrap each bam-player element in a div
    const playerWrapper = document.createElement('div');
    playerWrapper.classList.add('player-wrapper');
    playerWrapper.appendChild(bamPlayer);

    this._playerContainer.appendChild(playerWrapper);

    // TODO: Update these to make a true never-ending carousel
    playerWrapper.style.order = 
      Array.from(this._playerContainer.querySelectorAll('.player-wrapper')).indexOf(playerWrapper) + 1; 

    return bamPlayer;
  };

  _loadNextPaginationCursor = async () => {
    // If no next page available, let's do nothing obviously
    if (!this._nextPaginationCursor) return;

    const { focusMode, autoplay, limit, playerFit, landscapeInPortrait } = this._attr;
    // check current amount of available players in player container
    const currentAmountPlayers = Array.from(this._playerContainer.childNodes).filter((n) => n.classList.contains('player-wrapper')).length;
    // if the configured limit is reached, do nothing
    if (currentAmountPlayers >= limit) return;

    let playlist = [];
    try {
      const { playlist: actualPlaylist, nextCursor } = await loadPlaylist({
        ...this._attr,
        cursor: this._nextPaginationCursor,
      });

      playlist = actualPlaylist;
      this._nextPaginationCursor = nextCursor;
    } catch (err) {
      console.error('Failed to load playlist with cursor', { err, cursor: this._nextPaginationCursor });
    }

    // Create a bam-player for each show in the playlist until we've reached the configured limit
    this._players = this._players.concat(...playlist.slice(0, limit - currentAmountPlayers).map((eventId) => this._createBamPlayer({
      eventId,
      focusMode,
      autoplay,
      isSingleVideo: playlist.length === 1,
      playerFit,
      landscapeInPortrait,
    })));

    if (!this._nextPaginationCursor || this._players.length >= limit) {
      // No more pagination cursor or reached player limit, ditch load more button
      this._loadMoreButton?.remove();
    }
  };

  _blockScroll() {
    if (this._playerContainer.style.overflow !== 'hidden') {
      this._playerContainer.style.overflow = 'hidden';
    }
  }

  _unblockScroll() {
    if (this._playerContainer.style.overflow === 'hidden') {
      this._playerContainer.style.overflow = '';
    }
  }

  _expand() {
    if (this._isExpanded) return;
    this._isExpanded = true;

    const wrapper = this._wrapper;
    wrapper.classList.add('is-expanded');

    this._players.forEach((player) => {
      player.updateScaleMode();
    });

    // Keep a copy of the styles currently set on the body
    this._bodyStylesCopy = copyStyleFromEl(document.body);
    this._overrideAncestorsStyles();

    // Set the body to a fixed position to prevent scrolling
    document.body.style.overflow = 'hidden';
    document.body.style.width = '100%';
    document.body.style.padding = '0';
    document.body.style.margin = '0';

    // Stop autoplay cascade if it's running
    this._stopAutoplayCascade();
  }

  _contract() {
    if (!this._isExpanded) return;
    this._isExpanded = false;

    this._players.forEach((player) => {
      player.setScaleMode('aspectFill');
    });

    this._wrapper.classList.remove('is-expanded');
    const focusedPlayer = this._focusedPlayer;
    this._setFocusedPlayer(null);

    // Reset the styles on the body to what they were before we expanded
    applyStyleToEl(document.body, this._bodyStylesCopy);
    this._revertAncestorsStyles();

    // Let's force unload all players to prevent any inconsistencies
    Array.from(this._playerContainer.childNodes).filter((n) => n.classList.contains('player-wrapper')).forEach((playerWrapper) => {
      const player = playerWrapper.children[0];
      player.pause();
      player._unloadPlayerUI();
    });

    // Restart autoplay cascade if it should be running. Let's start from the
    // player that was focused before we contracted.
    this._startAutoplayCascade(focusedPlayer);
  }

  _getPlayerById(id) {
    return this._players.find((player) => player._id === id);
  }

  _setFocusedPlayer(player) {
    if (this._focusedPlayer && this._focusedPlayer !== player) {
      this._focusedPlayer.pause();

      const lastFocusedPlayerId = this._focusedPlayer._id;
      clearTimeout(this._playerVisibilityMap[lastFocusedPlayerId]);
      this._playerVisibilityMap[lastFocusedPlayerId] = undefined;

      this._playerVisibilityMap[lastFocusedPlayerId] = setTimeout(() => {
        if (lastFocusedPlayerId !== this._focusedPlayer?._id) {
          this._getPlayerById(lastFocusedPlayerId)?._unloadPlayerUI();
        }
      }, UNLOAD_PLAYER_UI_DELAY_MS);

      this._focusedPlayer.parentNode.classList.remove('focused');
    }

    if (!player) {
      this._focusedPlayer = null;
      return;
    }

    this._focusedPlayer = player;
    if (this._focusedPlayer._isPlayerUILoaded) {
      this._focusedPlayer.play();
    } else {
      this._focusedPlayer._loadPlayerUI();
    }
    this._focusedPlayer.parentNode.classList.add('focused');
  }

  _startAutoplayCascade(startFromPlayer = null) {
    if (!this._players?.length && !startFromPlayer) return;
    if (this._attr.autoplay !== 'cascade') return;
    if (this._isCascadeRunning) return;
    this._isCascadeRunning = true;
    this._curCascadePlayer = null;
    const moveToNextPlayer = () => {
      // Either start from a given player or choose next one in the series
      let nextPlayer;
      if (startFromPlayer) {
        nextPlayer = startFromPlayer;
        startFromPlayer = null;
      } else {
        nextPlayer = this._players[this._players.indexOf(this._curCascadePlayer) + 1];
        if (!nextPlayer) {
          this._stopAutoplayCascade();
          return;
        }
      }

      const onPlaying = () => {
        nextPlayer.removeEventListener('playing', onPlaying);
        // Stop the previous player and promote the next one to the current player
        this._curCascadePlayer?.setAttribute('autoplay', 'hover');
        this._curCascadePlayer = nextPlayer;

        if (!this._isCascadeRunning) {
          nextPlayer?.setAttribute('autoplay', 'hover');
          return;
        }

        // Scroll the container to reveal the next player
        scrollToElement(this._playerContainer, nextPlayer);

        // Move on to the next player after a while if there are more than one player
        if (this._players.length > 1) {
          this._cascadePlayerTimeoutHandle = setTimeout(moveToNextPlayer, AUTOPLAY_CASCADE_PLAY_DURATION_MS);
        }
      };
      nextPlayer.addEventListener('playing', onPlaying);

      // Start playback on the next player
      nextPlayer.setAttribute('autoplay', 'visible');
    };
    moveToNextPlayer();
  }

  _stopAutoplayCascade() {
    this._curCascadePlayer?.pause();
    clearTimeout(this._cascadePlayerTimeoutHandle);
    this._isCascadeRunning = false;
  }

  _determineScrollDirection() {
    // This should hopefully match our landscape/portrait media queries
    const prevScrollDir = this._scrollDirection;
    this._scrollDirection = window.innerHeight > window.innerWidth ? 'vertical' : 'horizontal';
    if (prevScrollDir !== this._scrollDirection) {
      this._scrollToPlayer(this._focusedPlayer, false, false);
    }
  }

  get _nextPlayer() {
    if (!this._focusedPlayer) return this._players[0];
    const index = this._players.indexOf(this._focusedPlayer);
    return this._players[index + 1];
  }

  _next() {
    if (this._isExpanded && this._players.length > 1) {
      const nextPlayer = this._players[this._players.indexOf(this._focusedPlayer) + 1] || this._players[0];
      this._scrollToPlayer(nextPlayer);

      // check if we're on the last player, if so, let's load more
      if (this._players.indexOf(nextPlayer) === this._players.length - 1) {
        this._loadNextPaginationCursor();
      }
    }
  }

  _prev() {
    if (this._isExpanded && this._players.length > 1) {
      const prevPlayer = this._players[this._players.indexOf(this._focusedPlayer) - 1] || this._players[this._players.length - 1];

      this._scrollToPlayer(prevPlayer);
    }
  }

  _scrollToPlayer(player, animated = true, hideCurrentFocusedInstantly = true) {
    if (!player) return;

    if (hideCurrentFocusedInstantly && this._isExpanded && this._focusedPlayer?.parentElement) {
      this._focusedPlayer.parentElement.classList.remove('focused');
      this._focusedPlayer.parentElement.style.opacity = '';
    }

    player.scrollIntoView({
      ...animated && { behavior: 'smooth' },
      block: 'center',
      inline: 'center'
    });
  }

  _onScroll() {
    if (!this._isExpanded) return;

    if (this._scrollDirection === 'vertical' || !this._focusedPlayer) {
      this._players.forEach((player) => {
        const visibleArea = player.getVisibleArea();
        const originalOpacity = player.parentElement.style.opacity;

        if(visibleArea > 0.25 && originalOpacity !== '1') {
          player.parentElement.style.opacity = 1;
        } else if (visibleArea < 0.25 && originalOpacity !== '') {
          player.parentElement.style.opacity = '';
        }
      });
    } 
    else if (this._scrollDirection === 'horizontal') {
      const focusedPlayerId = this._players.indexOf(this._focusedPlayer);

      const focusPlayerPositionRelativeToCenter = this._focusedPlayer.positionRelativeToCenter();
      
      if (focusPlayerPositionRelativeToCenter === HORIZONTAL_POSITION.LEFT) {
        const nextPlayer = this._players[focusedPlayerId + 1];

        if (nextPlayer) {
          const originalOpacity = nextPlayer.parentElement.style.opacity;
          const nextPlayerVisibleArea = nextPlayer.getVisibleArea();
          if (nextPlayerVisibleArea > 0.1 && originalOpacity !== "1") {
            nextPlayer.parentElement.style.opacity = 1;
          } else if (nextPlayerVisibleArea <= 0.1 && originalOpacity !== "") {
            nextPlayer.parentElement.style.opacity = "";
          }
        }
      }
      
      if (focusPlayerPositionRelativeToCenter === HORIZONTAL_POSITION.RIGHT) {
        const prevPlayer = this._players[focusedPlayerId - 1];

        if (prevPlayer) {
          const originalOpacity = prevPlayer.parentElement.style.opacity;
          const prevPlayerVisibleArea = prevPlayer.getVisibleArea();
          if (prevPlayerVisibleArea > 0.1 && originalOpacity !== "1") {
            prevPlayer.parentElement.style.opacity = 1;
          } else if (prevPlayerVisibleArea <= 0.1 && originalOpacity !== "") {
            prevPlayer.parentElement.style.opacity = "";
          }
        }
      }
    }

    if (!('onscrollend' in window)) {
      clearTimeout(this._scrollEndTimeout);
      this._scrollEndTimeout = setTimeout(() => {
        this._onScrollEnd();
      }, 100);
    }
  }

  _onScrollEnd() {
    if (!this._isExpanded) return;

    const visibleAreas = this._players.map((player) => player.getVisibleArea());
    const centeredPlayer = this._players.find(
      player => player.positionRelativeToCenter() === HORIZONTAL_POSITION.CENTER
    );

    // If any player is more than 80% visible at the end of the scroll
    // we should focus on something
    // It's important because we can't distiguish end of the scroll
    // as the pause of movement 
    // and actual release of scroll handle universally
    
    const playerToFocus = this._scrollDirection === 'horizontal'
      ? centeredPlayer
      : this._players[visibleAreas.indexOf(Math.max(...visibleAreas))];

    const shouldFocus = playerToFocus && visibleAreas.some((area) => area > 0.9);

    // Euristic to determine if the players on the sides of the focused player should be darkened
    if (this._scrollDirection === 'horizontal' && centeredPlayer) {
      this._players.forEach((player) => {
        if(player.positionRelativeToCenter() !== HORIZONTAL_POSITION.CENTER) {
          player.parentElement.style.opacity = '';
        }
      });
    }

    if (shouldFocus && playerToFocus !== this._focusedPlayer) {
      this._setFocusedPlayer(playerToFocus);
    }
  }
}
