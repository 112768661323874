// all values are in pixels, excepted if specific comments

// mini-player
export const EDGE = {
  SPACING_MOBILE: 8,
  SPACING_DESKTOP: 16,
};
export const MIN_WIDTH = {
  SMALL: {
    MOBILE: 120,
    DESKTOP: 180,
  },
  LARGE: {
    MOBILE: 150,
    DESKTOP: 200,
  },
};
export const MAX_WIDTH_FRACTION = {
  SMALL: {
    MOBILE: 0.3,
    DESKTOP: 0.3,
  },
  LARGE: {
    MOBILE: 0.38,
    DESKTOP: 0.4,
  },
};
export const SNAP = {
  ENABLED: true,
  HORIZONTAL_THRESHOLD_ON_MOBILE: 1 / 2, // ratio of the screen width
  VERTICAL_THRESHOLD_ON_MOBILE: 0, // ratio of the screen height
  HORIZONTAL_THRESHOLD_ON_DESKTOP: 1 / 2, // ratio of the screen width
  VERTICAL_THRESHOLD_ON_DESKTOP: 0, // ratio of the screen height
  THRESHOLD_OFF: 2,
};

export const QUICK_MOVE = {
  ENABLED: false, // need some improvement since we switch to "always snap to corners"
  THRESHOLD_X_DESKTOP: 40,
  THRESHOLD_Y_DESKTOP: 25,
  THRESHOLD_X_MOBILE: 15,
  THRESHOLD_Y_MOBILE: 20,
  MIN_COUNT_THRESHOLD: 3, // number of quick moves within the DETECTION_DURATION to concider that it's valid
  DETECTION_DURATION: 50, // ms
};

export const autoplayPlayerConfigWaitInterval = 300; // ms
export const autoplayPlayerConfigWaitNumberOfTry = 14; // * autoplayPlayerConfigWaitInterval =~ 4s
