export const KEY = {
  isEnter: (e) => (e.key ? e.key === 'Enter' : (e.charCode && e.charCode === 13) || (e.keyCode && e.keyCode === 13)),
  isSpace: (e) => (e.key ? e.key === ' ' : (e.charCode && e.charCode === 32) || (e.keyCode && e.keyCode === 32)),
  isTab: (e) => (e.key ? e.key === 'Tab' : (e.charCode && e.charCode === 9) || (e.keyCode && e.keyCode === 9)),
  isEsc: (e) =>
    e.key
      ? e.key === 'Escape' || e.key === 'Esc' /* IE/Edge */
      : (e.charCode && e.charCode === 27) || (e.keyCode && e.keyCode === 27),
  isUp: (e) =>
    e.key
      ? e.key === 'ArrowUp' || e.key === 'Up' /* IE/Edge */
      : (e.charCode && e.charCode === 38) || (e.keyCode && e.keyCode === 38),
  isDown: (e) =>
    e.key
      ? e.key === 'ArrowDown' || e.key === 'Down' /* IE/Edge */
      : (e.charCode && e.charCode === 40) || (e.keyCode && e.keyCode === 40),
  isLeft: (e) =>
    e.key
      ? e.key === 'ArrowLeft' || e.key === 'Left' /* IE/Edge */
      : (e.charCode && e.charCode === 37) || (e.keyCode && e.keyCode === 37),
  isRight: (e) =>
    e.key
      ? e.key === 'ArrowRight' || e.key === 'Right' /* IE/Edge */
      : (e.charCode && e.charCode === 39) || (e.keyCode && e.keyCode === 39),
};

/**
 * Return true if it is a keyboard forward navigation
 * @param {Event} e
 */
export const isForwardNav = (e) => KEY.isTab(e) && !e.shiftKey;

/**
 * Return true if it is a keyboard backward navigation
 * @param {Event} e
 */
export const isBackNav = (e) => KEY.isTab(e) && e.shiftKey;

/**
 * Return true if the SPACE or the ENTER key is pressed
 * @param {Event} e
 */
export const isActionKey = (e) => KEY.isSpace(e) || KEY.isEnter(e);

/**
 * Return true if the ESCAPE key is pressed
 * @param {Event} e
 */
export const isEscape = (e) => KEY.isEsc(e);
