const urlParams = new URLSearchParams(window.location.search);

export default class Url {
  static isParamTrue(name) {
    const paramValue = urlParams.get(name);
    return paramValue && !!paramValue.match(/^(1|true)$/);
  }

  static isParamFalse(name) {
    const paramValue = urlParams.get(name);
    return paramValue && !!paramValue.match(/^(0|false)$/);
  }
}
