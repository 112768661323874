import { AbstractBambuserLSConfigurationTransformer } from './AbstractBambuserLSConfigurationTransformer';
import { BambuserLiveShoppingError } from '../errors/BambuserLiveShoppingError';
import { BambuserLiveShoppingPlayerAPIDelegate } from './BambuserLiveShoppingPlayerAPIDelegate';

/**
 * @constant _experimentalProperties
 * Provides an overview of the "non-standard" properties
 * we use to be able to move "quickly" without unecessesarily
 * breaking or bloating our public API. Provided in the
 * "experimental" property of the config.
 */
const _experimentalProperties = [
  // normally this is set to external in almost all cases
  // there are some other values though [internal, inline]
  // only set these if you know what you're doing
  'cartType',

  // sets the chatname before loading the player
  // used, for example, when customer already has a chat name
  // in the current page context or in an app
  'chatName',

  // sets that the user has already accepted terms
  // somewhere else and do not need to accept it again
  // for example in an app
  'hasAcceptedTerms',

  'playerUrl',

  // Applies the provided value as-is to the created player & surf iframes.
  // Example:
  // 'allow-forms allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-top-navigation allow-scripts'
  'sandboxAttributes',

  // Instruct the embed to add the "credentialless" attribute on all iframes
  // See "credentialless" here https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe
  // More info: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Cross-Origin-Embedder-Policy
  'credentiallessIframes',
];

/**
 * Transforms the outcome of a BambuserLiveShoppingConfiguration instance
 * into a format compatible with the classic embed.js configuration
 */
export class BambuserLSLegacyConfigurationTransformer extends AbstractBambuserLSConfigurationTransformer {
  /**
   * Create a new transformer for provided delegate
   * @param {BambuserLiveShoppingPlayerAPIDelegate} delegate
   */
  constructor(delegate) {
    super();

    if (!(delegate instanceof BambuserLiveShoppingPlayerAPIDelegate)) {
      throw new BambuserLiveShoppingError(
        'Cannot create a new BambuserLSLegacyConfigurationTransformer without providing a valid instance of \
        BambuserLiveShoppingPlayerAPIDelegate',
      );
    }

    this._delegate = delegate;
  }

  /**
   * Transforms the configuration from the config instance
   * @param {BambuserLiveShoppingConfiguration} config
   * @return {object} legacy embed.js config
   */
  transform(config) {
    const publicAPI = this._delegate.publicAPI;
    const originalConfig = config.getConfig();
    const supportedEvents = this._delegate.getSupportedEvents();

    const legacyConfig = {
      enableFirstPartyCookies: originalConfig.enableFirstPartyCookies,
      cartType: 'external',
      checkoutTarget: 'new-window',
      currency: originalConfig.currency,
      disableChatInput: originalConfig.disableChatInput,
      locale: originalConfig.locale,
      withoutProductDetails: true,
      includeQueryParams: originalConfig.includeQueryParams,
      playerContainerNode: originalConfig.playerContainerNode,
      shareBaseUrl: originalConfig.shareBaseUrl,
      allowSoundControl: originalConfig.allowSoundControl,
      allowShareAutoplay: originalConfig.allowShareAutoplay,
      minimizedPosition: originalConfig.minimizedPosition,
      miniplayerSize: originalConfig.miniplayerSize,
      ...originalConfig.ui,
      cookie: originalConfig.cookie,
      trimPriceTrailingZeros: originalConfig.trimPriceTrailingZeros,
      checkoutOnCartClick: originalConfig.checkoutOnCartClick,
      startMuted: originalConfig.startMuted,
      shareTargets: originalConfig.shareTargets,
      _useExternalPlayerController: originalConfig._useExternalPlayerController,
      deeplink: originalConfig.deeplink,
      disableClickOutsideBehavior: originalConfig.disableClickOutsideBehavior,
      withGA: originalConfig.withGA,
      autoplay: originalConfig.autoplay,
      themeId: originalConfig.themeId,

      // to allow moving fast we can create experimental flags
      // for both testing & specific customers
      // without making them a part of the public API
      // and having to support them forever.
      // NOTE: Please remember to atleast document your custom flag above
      //       in _experimentalProperties
      ...originalConfig.experimental,
      ...originalConfig.miniPlayer,
    };

    // warn if using undocumented experimental properties
    if (!Object.keys(originalConfig.experimental || {}).every((c) => _experimentalProperties.includes(c))) {
      console.warn('Usage of undocumented experimental property', originalConfig.experimental);
      if (process.env.NODE_ENV === 'development') {
        console.warn('PLEASE ADD DOCUMENTATION in _experimentalProperties constant');
      }
    }

    // events
    const eventsRequiredForProductView = [
      publicAPI.EVENT.ADD_TO_CART,
      publicAPI.EVENT.CHECKOUT,
      publicAPI.EVENT.PROVIDE_PRODUCT_DATA,
    ];
    if (eventsRequiredForProductView.every((e) => supportedEvents.includes(e))) {
      legacyConfig.withoutProductDetails = false;
    }

    // buttons
    if (originalConfig.buttons) {
      // checkout behavior
      const checkoutButtonBehavior = originalConfig.buttons['checkout'];
      switch (checkoutButtonBehavior) {
        case publicAPI.BUTTON.AUTO:
        case publicAPI.BUTTON.LINK:
          break;
        case publicAPI.BUTTON.MINIMIZE:
          legacyConfig.checkoutTarget = 'current-window';
          legacyConfig.withMinimizeSupport = true;
          break;
        default:
          console.error(`"${checkoutButtonBehavior}" not yet supported as Checkout Button`);
          break;
      }

      // close behavior
      const closeButtonBehavior = originalConfig.buttons['dismiss'];
      switch (closeButtonBehavior) {
        case publicAPI.BUTTON.AUTO:
          break;
        case publicAPI.BUTTON.CLOSE:
          legacyConfig.withMinimizeSupport = false;
          break;
        case publicAPI.BUTTON.MINIMIZE:
          legacyConfig.withMinimizeSupport = true;
          break;
        case publicAPI.BUTTON.NONE:
          legacyConfig.hideCloseButton = true;
          break;
        default:
          console.error(`"${closeButtonBehavior}" not yet supported as Close Button`);
          break;
      }

      // product click behavior
      const productButtonBehavior = originalConfig.buttons['product'];
      switch (productButtonBehavior) {
        case publicAPI.BUTTON.AUTO:
          break;
        case publicAPI.BUTTON.LINK:
          legacyConfig.withoutProductDetails = true;
          legacyConfig.openProductsTarget = 'new-window';
          break;
        case publicAPI.BUTTON.MINIMIZE:
          legacyConfig.withMinimizeSupport = true;
          legacyConfig.withoutProductDetails = true;
          break;
        case publicAPI.BUTTON.INLINE:
          legacyConfig.openProductsTarget = 'inline';
          break;
        case publicAPI.BUTTON.NONE:
          legacyConfig.openProductsTarget = 'none';
          break;
        default:
          console.error(`"${productButtonBehavior}" not yet supported as Product Click Behavior`);
          break;
      }

      // floating player
      if (originalConfig.floatingPlayer) {
        // site navigation mode
        const { navigationMode = null } = originalConfig.floatingPlayer;
        if (navigationMode) {
          switch (navigationMode) {
            case publicAPI.FLOATING_PLAYER_NAVIGATION_MODE.IFRAME:
            case publicAPI.FLOATING_PLAYER_NAVIGATION_MODE.MANUAL:
              legacyConfig.surfBehindMode = navigationMode;
              break;
            case publicAPI.FLOATING_PLAYER_NAVIGATION_MODE.IFRAME_SRCDOC:
              legacyConfig.surfBehindMode = navigationMode;
              console.warn(`Using floating player navigation mode "${navigationMode}" is experimental`);
              break;
            default:
              console.error(`Using floating player navigation mode "${navigationMode}" not supported`);
              break;
          }
        }
      }
    }

    if (legacyConfig.locale) {
      // Translate improperly formatted locales (underscore instead of dash)
      legacyConfig.locale = String(legacyConfig.locale).replace('_', '-');
    }

    return legacyConfig;
  }
}
