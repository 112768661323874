import BambuserPlayerComponent from './bamPlayer';
import BambuserBundleComponent from './bamBundle';

class BambuserPlaylistComponent extends BambuserBundleComponent {}

const installWebComponents = () => {
  // Define custom elements if the browser supports it and they are not already defined
  [
    ['bam-player', BambuserPlayerComponent],
    ['bam-bundle', BambuserBundleComponent], // legacy, to be removed
    ['bam-playlist', BambuserPlaylistComponent],
  ].forEach(([name, component]) => {
    if (!window?.customElements?.define) return;
    if (!window.customElements.get(name)) {
      window.customElements.define(name, component);
    }
  });

  if (window) {
    window.injectBambuserPlayer = (config) => {
      if (!config?.orgId) {
        console.warn('injectBambuserPlayer: Invalid configuration, missing orgId. Aborting!');
        return;
      }
      if (!config?.containers || !Array.isArray(config.containers)) {
        console.warn('injectBambuserPlayer: Invalid configuration, missing containers. Aborting!');
        return;
      }

      for (const container of config.containers) {
        // Verify the container selector
        if (!container?.selector) {
          console.warn('injectBambuserPlayer: Invalid container configuration, missing selector. Skipping!');
          continue;
        }
        const holder = document.querySelector(container.selector);
        if (!holder) {
          console.warn(`injectBambuserPlayer: Selector "${container.selector}" didn't match any DOM node. Skipping!`);
          continue;
        }

        // Create component and set attributes
        const containerComponent = document.createElement('bam-playlist');
        containerComponent.setAttribute('org-id', config.orgId);
        if (container.focusMode) containerComponent.setAttribute('focus-mode', container.focusMode);
        if (container.autoPlayMode) containerComponent.setAttribute('autoplay', container.autoPlayMode);
        if (container.layoutMode) containerComponent.setAttribute('mode', container.layoutMode);
        if (container.id) containerComponent.setAttribute('id', container.id);
        if (container.playlistId) containerComponent.setAttribute('playlist-id', container.playlistId);
        if (container.query) containerComponent.setAttribute('query', container.query);

        // Add component to DOM tree at the specified location
        holder.appendChild(containerComponent);
      }
    };
  }
};

export { installWebComponents };
