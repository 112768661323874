import { BambuserLiveShoppingError } from '../errors/BambuserLiveShoppingError';
import { PLAYER_EVENTS } from '../constants';

/**
 * Exposes functionality from the embed instance
 * in a compatible format for the new public api
 */
export class BambuserLiveShoppingPlayerAPIEmbedInstanceAdapter {
  /**
   * @param {BambuserLiveShopping} embedInstance
   */
  constructor(embedInstance) {
    if (!embedInstance) {
      throw new BambuserLiveShoppingError(
        `Cannot create a BambuserLiveShoppingPlayerAPIEmbedInstanceAdapter
        without an instance of embed.js (BambuserLiveShopping)`,
      );
    }
    this._embedInstance = embedInstance;

    // expose some methods directly
    this.destroy = () => this._embedInstance.destroy();
    this.minimize = () => this._embedInstance.minimize();
    this.unminimize = () => this._embedInstance.unminimize();
    this.showCheckout = (checkoutUrl) => this._embedInstance.showCheckout(checkoutUrl);
    this.updatePlayerCart = (cartData) => this._embedInstance.updatePlayerCart(cartData);
    this.updateProducts = (products) => this._embedInstance.updateProducts(products);
    this.hideUI = (uiSections) => this._embedInstance.hideUI(uiSections);
    this.showUI = () => this._embedInstance.showUI();
    this.currentPlayerController = () => this._embedInstance.currentPlayerController();
    this.playerControllers = () => this._embedInstance.playerControllers();
    this.externalPlayerControllerEvent = (data) => this._embedInstance.externalPlayerControllerEvent(data);
    this.play = () => this._embedInstance.play();
    this.pause = () => this._embedInstance.pause();
    this.showProductList = () => this._embedInstance.showProductList();
    this.hideProductList = () => this._embedInstance.hideProductList();
    this.getHighlightedProductsList = () => this._embedInstance.playerApiCall(PLAYER_EVENTS.PRODUCT_HIGHLIGHT_LIST, null, true);
    this.seekToPercent = (seekToPercentage) => this._embedInstance.seekToPercent(seekToPercentage);
    this.mute = () => this._embedInstance.mute();
    this.unmute = () => this._embedInstance.unmute();
    this.requestPictureInPicture = () => this._embedInstance.playerApiCall(PLAYER_EVENTS.REQUEST_PICTURE_IN_PICTURE, null, true);
    this.exitPictureInPicture = () => this._embedInstance.playerApiCall(PLAYER_EVENTS.EXIT_PICTURE_IN_PICTURE, null, true);
  }
}
