import { PLAYER_BUTTON_BEHAVIORS } from '../constants';
import { deepMergeObjects } from '../utils/helpers';
import { AbstractBambuserLSConfigurationTransformer } from './AbstractBambuserLSConfigurationTransformer';
import { BambuserLiveShoppingError } from '../errors/BambuserLiveShoppingError';

/**
 * @private
 * Contains configuration
 */
const _config = new WeakMap();

export class BambuserLiveShoppingConfiguration {
  constructor() {
    this.setConfig({});
  }

  /**
   * @constant configurableButtons
   * Sets which buttons a customer can configure
   */
  get configurableButtons() {
    return [
      // configures the behavior of the checkout button
      'checkout',

      // configures the behavior of the dismiss button (i.e. close button)
      'dismiss',

      // configures behavior when user clicks on a product highlight or a product in the product list
      'product',
    ];
  }

  /**
   * @constant configurableProperties
   * Sets what a customer can send as configuration options
   */
  get configurableProperties() {
    return [
      // buttons allows configuration of how buttons behave within the player
      'buttons',

      // currency sets the global default currency for the page
      'currency',

      // optional configuration.
      // When set to true the client won't be able to chat, but they still we be able to see incoming chat messages.
      'disableChatInput',

      // setting this to true
      // allows use to store cookies on the domain
      // where the player resides
      'enableFirstPartyCookies',

      // experimental is a object specifying "not-fully-thought-through" flags
      // allowing us to move fast for new customers
      // while not creating a huge technical debt
      'experimental',

      // locale sets the global default locale
      'locale',

      // optional configuration for the floating player
      'floatingPlayer',

      // optional configuration to fake live user interface on an archived show
      'mockLive',

      // optional configuration for query parameters to be included in the
      // URL's we use to track where Players have been embedded into
      'includeQueryParams',

      // optional configuration to set the DOM node of the player container
      // if no node is specified, the player will reside inside <body>
      'playerContainerNode',

      // optional configuration to define the base URL for the share link
      // should start with http:// or https://
      // if protocol is missing it will default to https://
      'shareBaseUrl',

      // optional configuration to add a mute button on the desktop version
      'allowSoundControl',

      // An object containing configs to enable/disable UI in the Bambuser live shopping player.
      // Although a specific UI itself is disabled, the player may still emit events for the functionality
      'ui',

      // Object containing mini-player related configs
      'miniPlayer',

      // optional configuration for cookie options
      'cookie',

      'allowShareAutoplay',
      'trimPriceTrailingZeros',

      // The initial position of the miniplayer
      // Can be 'bottom-left', 'bottom-right', 'top-right', 'top-left'
      // 'bottom-right' is the default value
      'minimizedPosition',

      // The size of the miniplayer
      // Now can be 'large' or 'small'
      // 'small' is the default value
      'miniplayerSize',

      // Make any action which is supposed to present the in-player cart trigger the checkout action instead
      'checkoutOnCartClick',

      // Prefer the player to start in a muted state
      'startMuted',

      // shareTargets is an array with share targets, which may be either strings or objects
      // if the share target is a string, it will be used as the share target identifier
      // the case when the share target is an object is reserved for future use 
      // (if we need to pass additional data to the share target)
      'shareTargets',

      // Use the ExternalPlayerController in player
      '_useExternalPlayerController',

      // Deeplink string which should consist of a broadcast hash and timestamp, with an @ sandwiched in this string
      // will be parsed in the embed and replace the broadcastHash and timestamp properties passed to the player.
      'deeplink',

      // Disable click outside of the player to close/minimize it
      'disableClickOutsideBehavior',

      // Allow to enable Google Analytics tracking
      'withGA',

      // Signal that this player should automatically played when inititialized or not
      'autoplay',

      // Allow a themeId to be passed to the player via the configuration
      'themeId',
    ];
  }

  /**
   * @constant configurableFloatingPlayerProperties
   * Sets which floating player properties a customer can configure
   */
  get configurableFloatingPlayerProperties() {
    return [
      // configures the site navigation mode that should be used togheter with
      // the floating player, possible values are:
      // * iframe (default) - Will add an iframe above the existing site where
      //   all navigation will happen in with floating player placed above so it
      //   can live between page navigations.
      // * manual - Only shows the floating player, how site navigation works is
      //   up to the embed implementator. Most suitable with SPAs where the page
      //   content is updated without any full page loads.
      // * srcdoc - An experimental fallback when there isn't possible to use
      //   any of the above. Implies much magic and the stars needs to be
      //   aligned so needs to be verified on a site to site basis, uses for
      //   example an iframe and loads the html content with XHR and leverages
      //   srcdoc attribute to provide the html to be rendered inside the
      //   iframe. But can be a way to use the floating player on a domain that
      //   has x-frame-options deny (ex. Shopify sites).
      'navigationMode',
    ];
  }

  /**
   * @constant configurableUIPlayerProperties
   * Sets which UI player properties a customer can configure
   */
  get configurableUIPlayerProperties() {
    return [
      // Hide productView on product item click (ProductList / highlight)
      'hideActionBar',
      'hideProductView',
      'hideCartButton',
      'hideCartView',
      'hideChatOverlay',
      'hideEmojiOverlay',
      'hideProductList',
      'hideShareButton',
      'hideShareView',
      'hideShare', // Remove once no client is using 'hideShare' anymore. hideShare===hideShareView)
      'hideAddToCalendar',
      'hideAddToCalendarButton',
      'showShareButtonInMobileActionBar',
    ];
  }

  /**
   * @constant configurableCookiePlayerProperties
   * Sets which Cookie player properties a customer can configure
   */
  get configurableCookiePlayerProperties() {
    return ['domain', 'activityCookieTTLDays'];
  }

  /**
   * Default configuration
   * - merged with provided configuration
   */
  get defaultConfiguration() {
    const defaultConfiguration = {
      // storing cookies on customers site is opt-in
      enableFirstPartyCookies: true,
      includeQueryParams: ['bamTag', 'shareLiveShopping', 'socialMediaLiveshopping'],
    };

    // buttons always default to auto
    defaultConfiguration.buttons = {};
    // eslint-disable-next-line
    for (const buttonName of this.configurableButtons) {
      defaultConfiguration.buttons[buttonName] = PLAYER_BUTTON_BEHAVIORS.AUTO;
    }

    return defaultConfiguration;
  }

  /**
   * Gets the currently set configuration
   * - does not include default values
   * @return {object} config
   */
  get rawConfig() {
    return { ..._config.get(this) };
  }

  /**
   * Get full configuration
   * - including default values
   * @return {object} config
   */
  getConfig() {
    return deepMergeObjects(this.defaultConfiguration, this.rawConfig);
  }

  /**
   * Get configuration transformed using given transformer
   * @param {AbstractBambuserLSConfigurationTransformer} transformer
   * @return {object} transformed config
   */
  getConfigUsingTransformer(transformer) {
    if (!(transformer instanceof AbstractBambuserLSConfigurationTransformer)) {
      throw new BambuserLiveShoppingError(
        'getConfigUsingTransformer() requires a valid subclass of AbstractBambuserLSConfigurationTransformer as first' +
          ' parameter',
      );
    }

    return transformer.transform(this);
  }

  /**
   * Set configuration
   * @param {object} config
   */
  setConfig(config) {
    _config.set(this, config);
  }
}
