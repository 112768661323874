import { KEY } from './keyboard';

export default class Navigation {
  static get USING_KEYBOARD() {
    return 'using-keyboard';
  }

  static addUsingKeyboardClass() {
    document.body.classList.add(this.USING_KEYBOARD);
  }

  static removeUsingKeyboardClass() {
    document.body.classList.remove(this.USING_KEYBOARD);
  }

  static addUsingKeyboardClassOnTabNavigation(e) {
    if (KEY.isTab(e)) {
      this.addUsingKeyboardClass();
    }
  }

  static enableModeDetection() {
    document.body.addEventListener('mousedown', this.removeUsingKeyboardClass.bind(this), true);
    document.body.addEventListener('keydown', this.addUsingKeyboardClassOnTabNavigation.bind(this), true);
  }

  static disableModeDetection() {
    document.body.removeEventListener('mousedown', this.removeUsingKeyboardClass);
    document.body.removeEventListener('keydown', this.addUsingKeyboardClassOnTabNavigation);
  }
}
