let _isAndroidDevice = null;
export function isAndroidDevice() {
  if (_isAndroidDevice === null) {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    _isAndroidDevice = /android/i.test(userAgent);
  }

  return _isAndroidDevice;
}

let _isAndroidWebView = null;
export function isAndroidWebView() {
  if (_isAndroidWebView === null) {
    _isAndroidWebView = isAndroidDevice() && navigator.userAgent && navigator.userAgent.indexOf('; wv') >= 0;
  }
  return _isAndroidWebView;
}

let _isAppleMobileDevice = null;
export function isAppleMobileDevice() {
  if (_isAppleMobileDevice === null) {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    _isAppleMobileDevice = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
  }

  return _isAppleMobileDevice;
}

let _isMacWithMultiTouch = null;
// This check detects iPad in desktop mode
export function isMacWithMultiTouch() {
  if (_isMacWithMultiTouch === null) {
    const isMultiTouchDevice = navigator.maxTouchPoints > 1;
    _isMacWithMultiTouch = navigator.platform === 'MacIntel' && isMultiTouchDevice && !window.MSStream;
  }

  return _isMacWithMultiTouch;
}

let _customPlatformName;
let _customPlatformVersion;
export function setCustomPlatform(name, version = 1) {
  _customPlatformName = name;
  _customPlatformVersion = typeof version === 'string' ? parseInt(version, 10) : version;

  if (name === 'ios') {
    _isAppleMobileDevice = true;
  } else if (name === 'android') {
    _isAndroidDevice = true;
  }
  _isAndroidWebView = null;
}

// H&M app uses a very custom user agent string that needs to be handled for us to detect that it is
// a mobile browser and specifically so we can run any needed iOS workarounds.
const hmUserAgentMatch = window.navigator.userAgent.match(/^targetapp_(android|ios)_([0-9]+).*$/);
if (hmUserAgentMatch) {
  setCustomPlatform(hmUserAgentMatch[1], parseInt(hmUserAgentMatch[2], 10));
}

export function getCustomPlatform() {
  if (!_customPlatformName) return null;

  return {
    name: _customPlatformName,
    version: _customPlatformVersion,
  };
}

export function isSafari() {
  return (
    navigator.vendor &&
    navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') === -1 &&
    navigator.userAgent.indexOf('FxiOS') === -1
  );
}

export function getIOSVersion() {
  if (_customPlatformName === 'ios') return _customPlatformVersion;
  let extract = navigator.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
  if (extract) return parseInt(extract[1], 10);
  extract = navigator.userAgent.match(/FBSV\/(\d+(\.\d)*);/);
  if (extract) return parseInt(extract[1], 10);
  return 0;
}

const _isFirefox = window.navigator.userAgent.match(/Firefox\//) && !navigator.userAgent.match(/Seamonkey\//);
export function isFirefox() {
  return _isFirefox;
}

export function getInstagramVersion() {
  const extract = navigator.userAgent.match(/Instagram (\d+)/);
  if (extract) return parseInt(extract[1], 10);
  return null;
}

export function isInstagramInAppBrowser() {
  return getInstagramVersion() !== null;
}

export function isBadInstagramInAppBrowser() {
  // This is totally crazy as Instagram is running some sort of A/B testing and is sometimes activating
  // a half useless browser that places native UI elements above the webview. This can happen in the same
  // app session by just switching between users, and one user could get this "bad" one and the other the
  // "good" one. The "bad" webview will have a much larger height than the "good" one, that is only way
  // that we've found out to detect this. (facepalm)
  // The "bad" in-app-browser places native top UI elements ontop of the webview and thus hiding ex. our
  // player top and bottom views... So we will just not support users in this kind of browser!
  return isInstagramInAppBrowser() && window.screen.height - window.innerHeight <= 44;
}

function iOSAllowPlayInsideInstagram(context) {
  return (
    context &&
    (context.withMinimizeSupport || context._isEmbedContext) &&
    isAppleMobileDevice() &&
    !isBadInstagramInAppBrowser()
  );
}

let _ubResult = null;
export function isUndesiredBrowser(context = {}) {
  return _ubResult !== null
    ? _ubResult
    : (_ubResult = (() => {
        // FTP redirect is disabled for iOS 15, and to avoid the player and embed
        // trying to make magic happend which could end up redirecting to the safari app
        // via FTP redirection found inside of tryOpenInExternalBrowser().
        // Let's just stick around in whatever browser we're currently in and revisit
        // this functionality and see if we can't hint to the user somehow to open the show
        // in a proper browser.
        // The next natural step if this function returned rue would be to try the functionality
        // in tryOpenInExternalBrowser().
        // TODO: In a perfect world we'd still like to test for chrome & firefox, but that would require
        // quite a bit of moving codes and state handling to accomplish.
        if (isAppleMobileDevice() && isSafari() && getIOSVersion() >= 15) return false;

        // https://app.slack.com/client/T044U6B4W/GMUJTG7QX/thread/GMUJTG7QX-1572600379.000300
        if (!navigator.userAgent) return false; // ?
        if (/Instagram/.test(navigator.userAgent)) {
          // May we support playback in Instagram in-app-browser on iOS devices with enabled miniplayer?
          if (iOSAllowPlayInsideInstagram(context)) {
            return false;
          }
          return { name: 'ig' };
        }
        if (/FBIOS|MessengerForiOS/.test(navigator.userAgent)) {
          return { name: 'fb' }; // FB, FB Messenger on iOS
        }
        if (/FB4A|FBAV|FBSV|FB_IAB/.test(navigator.userAgent)) {
          return {
            // FB, FB Messenger on Android (FB Lite unhandled... and unhandleable?; totally generic ua)
            name: 'fb',
            deniesIframeInitiatedRedirect: true,
          };
        }
        if (isAppleMobileDevice() && /LinkedInApp/.test(navigator.userAgent)) {
          return { name: 'linkedin' }; // TODO: handle Android
        }
        if (/Snapchat/.test(navigator.userAgent)) {
          return { name: 'snapchat', deniesIframeInitiatedRedirect: true };
        }
        return false;
      })());
}

/**
 * Tries everything to open a url in an external browser
 * Triggers onFail if it couldn't so you can handle it gracefully
 */
export async function tryOpenInExternalBrowser(
  url,
  {
    onRedirect = (url, { undesiredBrowser }) => (document.location = url),
    onFail = () => {}, // no failure handling by default
    onLog = (event, data) => {}, // no logging by default
  },
) {
  // const now = () => (new Date()).getTime();
  const undesiredBrowser = isUndesiredBrowser();
  if (!undesiredBrowser) return;
  // Only remove first occurence of protocol - there might be more in a query param
  const removeProtocol = (uri) => uri.split('//').slice(1).join('//');
  const cleanUrl = removeProtocol(url);
  let attempts = 0;

  let urlSchemes = [];
  if (isAppleMobileDevice() && isSafari()) {
    urlSchemes = [
      ...urlSchemes,
      ...[`googlechrome://${cleanUrl}`, `firefox://open-url?url=${encodeURIComponent(url)}`],
    ];
  }
  if (isAndroidDevice()) {
    urlSchemes = [...urlSchemes, ...[`intent://${cleanUrl}#Intent;scheme=http;end`]];
  }

  let browserIndex = -1;
  const tryNext = () => {
    browserIndex++;
    attempts++;
    const schemeUrl = urlSchemes[browserIndex];
    if (schemeUrl) {
      const escapeMethod = schemeUrl.split(':')[0];
      onLog('open_external_browser', {
        bls_ubn: undesiredBrowser.name,
        bls_ubm: escapeMethod,
      });
      onRedirect(schemeUrl, { undesiredBrowser });
    } else {
      clearAll();
      onFail();
      onLog('open_external_browser_fallback', {
        bls_ubn: undesiredBrowser.name,
        bls_ubm: 'none',
        attempts,
      });
    }
  };
  let trialAndError = setInterval(tryNext, isAppleMobileDevice() ? 1000 : 500);
  function clearAll() {
    clearInterval(trialAndError);
    trialAndError = null;
    document.removeEventListener('visibilitychange', handleVisibilityChange);
  }
  function handleVisibilityChange() {
    // If we detect that the document has been hidden we've found a way out of the undesired browser.
    // REMARK: On Android + Instagarm it may appear an "Open with" dialog (depending if user has a
    // default "always open" browser or not) and this event will fire for that dialog. So the user has
    // been given a choice to open in some installed browser but may still dismiss that dialog, and
    // then nothing will happen. User has to click to open show again and get same question about which
    // browser to open it in.
    if (document.hidden) {
      clearAll();
    }
  }
  document.addEventListener('visibilitychange', handleVisibilityChange);
  tryNext();
}
