export const BAM_PLAYER_FIT = {
  EXACT_SIZE: 'exact-size',
  SAME_HEIGHT: 'same-height',
  SAME_WIDTH: 'same-width',
  FILL_PARENT: 'fill-parent',
};

export const HORIZONTAL_POSITION = {
  CENTER: 0,
  LEFT: -1,
  RIGHT: 1,
};

export const SCALE_MODE = {
  FIT: 'fit',
  FILL: 'fill',
};