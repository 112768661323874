import { autoplayPlayerConfigWaitNumberOfTry, autoplayPlayerConfigWaitInterval } from '../config';
import { PLAYER_EVENTS_PUBLIC } from '../constants';
import { hasHashShowIdMatch } from './helpers';

function onInit(item, isAutoplayAttempt) {
  if (typeof item === 'string') {
    item = {
      type: 'overlay',
      eventId: item,
    };
  } else if (item.showId) {
    item.eventId = item.showId;
    delete item.showId;
  }

  const player = new window.BambuserLiveShopping(item);
  if (isAutoplayAttempt && !player.isAutoplayAllowed()) return player;

  // Legacy autoplay support (enabled by customers by removing the 'node' property).
  let respectLegacyAutoplay = false;
  if (window.top) {
    // Show the player automatically if the current JavaScript context is the top window (i.e. we're
    // not in a "surf behind iframe")
    if (window.top === window) {
      respectLegacyAutoplay = true;
    }
    // ... or if the user has navigated inside the surf behind iframe.
    try {
      if (window.top.location.href !== window.location.href) {
        respectLegacyAutoplay = true;
      }
    } catch {
      // Oh no, cross origin context, prevent it from crashing and just continue on...
    }
    // ... or if we're inside the surf behind iframe and initBambuserLiveShopping()
    // was called after the initial embed script initiation
    if (window.top !== window && !item._wasQueuedAtStartup) {
      respectLegacyAutoplay = true;
    }
  }

  if (item.type === 'overlay' && item.node) {
    item.node.addEventListener('click', function () {
      player.show();
    });
  } else if (item.node === null) {
    // document.getElementById('non-existing-id') will return null
    console.log('Element from property "node" in Bambuser Live Shopping configuration could not be found');
  } else if (respectLegacyAutoplay) {
    player.show();
  }

  return player;
}

function initBambuserLiveShopping(item, isAutoplayAttempt) {
  onInit(item, isAutoplayAttempt);
}

export function installReadyHandler(window, BambuserLiveShopping) {
  // First time (and hopefully only time) this script is loaded, notify any waiting callbacks.
  // Also ensure that any newly added callbacks is called directly as we are fully loaded now.
  if (window['initBambuserLiveShopping'] !== initBambuserLiveShopping) {
    const queue =
      window['initBambuserLiveShopping'] && window['initBambuserLiveShopping'].queue
        ? window['initBambuserLiveShopping'].queue
        : [];

    window['initBambuserLiveShopping'] = initBambuserLiveShopping;
    window.BambuserLiveShopping = BambuserLiveShopping;
    window.BambuserLivecommerce = BambuserLiveShopping; // Legacy interface
    // eslint-disable-next-line
    for (const item of queue) {
      try {
        item._wasQueuedAtStartup = true;
        onInit(item);
      } catch (e) {
        console.log(e); // Just log error and contine execute next callback.
      }
    }

    /**
     * Show id's can be provided via a URL hash. React to changes
     * in the hash value and start the player if the hash has the
     * expected format.
     */
    const parseShowIdFromHash = (isAutoplayAttempt) => {
      const hashMatch = hasHashShowIdMatch();
      if (hashMatch) {
        const player = onInit(hashMatch[1], isAutoplayAttempt);

        // Clear the hash when the player is closed
        player.playerDelegate._publicAPI.on(PLAYER_EVENTS_PUBLIC.CLOSE, () => {
          window.location.hash = '';
        });
      }
    };
    window.addEventListener('hashchange', parseShowIdFromHash);

    // If a show id is provided via a URL hash, use it when the DOM has loaded
    const waitForPlayerConfigThenParseHash = () => {
      let tries = autoplayPlayerConfigWaitNumberOfTry;
      const tick = () => {
        if (tries-- === 0 || window.onBambuserLiveShoppingReady) {
          // Go nuts when we see a player init callback function
          // or we've reached the max number of attempts to look
          // for it
          parseShowIdFromHash(true);
        } else {
          window.setTimeout(tick, autoplayPlayerConfigWaitInterval);
        }
      };
      tick();
    };
    if (document.readyState !== 'loading') {
      waitForPlayerConfigThenParseHash();
    } else {
      document.addEventListener('DOMContentLoaded', waitForPlayerConfigThenParseHash);
    }

    autoplayHandler(window);
  }
}

function autoplayHandler(window) {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('autoplayLiveShopping')) {
    const eventId = urlParams.get('autoplayLiveShopping');
    // TODO: Add a loader
    const delayAutoplayHandler = (i, delay) => {
      // We've waited & tried for some time but found no
      // onBambuserLiveShoppingReady, let's continue anyway.
      if (window.onBambuserLiveShoppingReady || i === 1) {
        // If we at this point the user have opened another player then lets not move on
        if (document.querySelector('[data-bambuser-liveshopping-player-id]')) return;
        // If the current location.href has a bamlvs hash available, abort as we do not want to
        // take over it's player intent
        if (hasHashShowIdMatch()) return;
        return window.initBambuserLiveShopping(eventId, true);
      };

      window.setTimeout(() => {
        delayAutoplayHandler(--i, delay);
      }, delay);
    };
    delayAutoplayHandler(autoplayPlayerConfigWaitNumberOfTry, autoplayPlayerConfigWaitInterval);
  }
}
