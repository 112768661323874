import { BambuserLiveShoppingError } from '../errors/BambuserLiveShoppingError';

/**
 * Can be used to transform the outcome of a BambuserLiveShoppingConfiguration instance
 */
export class AbstractBambuserLSConfigurationTransformer {
  /**
   * Transforms the configuration from the config instance
   * @param {BambuserLiveShoppingConfiguration} config
   * @return {object} transformed config
   */
  transform() {
    throw new BambuserLiveShoppingError('Cannot transform config using an abstract transformer!');
  }
}
