/**
 * Schema Model - generates object model by schema definitions
 * @example
 * class Model extends SchemaModel {
 *   static get model() {
 *     return {
 *       property: 'value',
 *     };
 *   }
 * }
 */
export class SchemaModel {
  constructor() {
    if (!this.constructor.model) throw new Error('Cannot generate a schema without a schema model');
    this._schema = {};
    this._decorators = {};

    this._autoAddChainMethodsForSchema(this.constructor.model);
  }

  /**
   * @private
   * Defines chained methods for all top properties in model
   * @param {object} schemaModel
   */
  _autoAddChainMethodsForSchema(schemaModel) {
    if (!schemaModel) return;

    Object.entries(schemaModel).forEach(([prop, value]) => {
      // handle references to another schema model
      if (value && value.prototype instanceof SchemaModel) {
        this._schema[prop] = new value(); // default value
        this[prop] = (callable) => {
          callable(this._schema[prop]);
          return this;
        };

        // handle references to an array with another schema model
      } else if (Array.isArray(value) && value[0] && value[0].prototype instanceof SchemaModel) {
        this._schema[prop] = []; // default value
        this[prop] = (callable) => {
          this._schema[prop] = callable(() => new value[0]());
          return this;
        };

        // handle plain values
      } else {
        this._schema[prop] = value || null; // default value
        this[prop] = (newValue) => {
          // loose comparator != tests for null AND undefined
          // eslint-disable-next-line
          if (newValue != null) this._schema[prop] = newValue;
          return this;
        };
      }
    });
  }

  /**
   * @private
   * Traverses the SchemaModels properties and creates a plain object
   * @param {SchemaModel} [parent] passes parent for reverse lookup for child models
   * @return {object}
   */
  _toObject(parent = null) {
    this._parent = parent;

    return Object.entries(this._schema).reduce(
      (acc, [prop, value]) => {
        if (value && value.prototype instanceof AbstractSchemaValue) value = new value();

        if (value instanceof AbstractSchemaValue) acc[prop] = value.get(this, prop);
        else if (value instanceof SchemaModel) acc[prop] = value._toObject(this);
        else if (Array.isArray(value) && value[0] instanceof SchemaModel)
          acc[prop] = value.map((sg) => sg._toObject(this));
        else acc[prop] = value;
        return acc;
      },
      {
        ...this._decorators,
      },
    );
  }

  /**
   * @public
   * Adds a decorator to the schema model
   * - Decorators allows you to add non defined
   *   properties that will only be returned when
   *   converting to an object
   * @param {string} name
   * @param {any} value
   * @throws Error if decorator name already exists as property
   */
  decorate(name, value) {
    if (this[name])
      throw new Error(`Cannot add decorator ${name} to ${this.constructor.name} as it already exists as a property`);

    this._decorators[name] = value;
    return this;
  }

  /**
   * @private
   * Converts model into a plain object structure according to schema
   * @return {object}
   */
  toObject() {
    return this._toObject();
  }
}

/**
 * Abstract class for inheriting new
 * custom SchemaValue(s)
 */
export class AbstractSchemaValue {
  /**
   * Called when value has not been overridden
   * in factory
   * @param {SchemaModel} model on which prop existed
   * @param {string} prop name for which get was called
   * @return {any} return value to insert at position in schema
   */
  get() {
    throw new Error('Cannot use AbstractSchemaValue as value directly');
  }
}

/**
 * Allow inheriting values from a parent SchemaModel
 * - if value does not exist - this is handled like a RequiredValue
 * @example
 * class SomeModel extends ParentModel {
 *   constructor() {
 *     super({
 *       someProperty: InheritedValue.from(ParentModel, 'someProperty')
 *     });
 *   }
 * }
 */
export class InheritedValue extends AbstractSchemaValue {
  /**
   * Creates a new inherited value
   * @param {SchemaModel} parentClass
   * @param {string} propertyName
   */
  constructor(parentClass, propertyName) {
    super();
    this._validate(parentClass, propertyName);

    this.parentClass = parentClass;
    this.propertyName = propertyName;
  }

  /**
   * Sets number of traversions up the model tree
   * before indicating failure
   * @return {int}
   */
  get maxLevelOfTraversion() {
    return 15;
  }

  /**
   * @private
   * Validates that the property exists on the SchemaModel
   * @param {SchemaModel} parentClass
   * @param {string} propertyName
   * @throws Error if property is invalid
   */
  _validate(parentClass, propertyName) {
    if (!parentClass || !propertyName) {
      throw new Error(
        'Cannot instantiate InheritedValue without specifying "parentClass" and "propertyName"' +
          ' - have you looked at the static "InheritedValue.from()" method?',
      );
    }

    if (!(parentClass.prototype instanceof SchemaModel)) {
      throw new Error('Cannot inherit values from classes not derived from SchemaModel');
    }

    if (!parentClass.model.hasOwnProperty(propertyName)) {
      throw new Error(`Cannot inherit value "${propertyName}" as it does not exist on parent "${parentClass.name}"`);
    }
  }

  /**
   * Traverses model's parents to find inherited value
   * - If none found - throws RequiredValue
   * @param {SchemaModel} model to traverse
   * @param {string} prop (unused)
   */
  get(model, prop) {
    let traversedParent = model;
    let traverseLevel = 0;
    while (!(traversedParent instanceof this.parentClass) && traverseLevel < this.maxLevelOfTraversion) {
      traversedParent = traversedParent._parent;
      traverseLevel++;
    }

    if (!(traversedParent instanceof this.parentClass)) {
      throw new Error(
        `Inherting value "${this.propertyName}" failed as parent "${
          new this.parentClass().constructor.name
        }" does not exist`,
      );
    }

    if (traversedParent._schema[this.propertyName]) {
      return traversedParent._schema[this.propertyName];
    }

    return new RequiredValue().get(model, prop);
  }

  /**
   * Static factory for InheritedValue
   * @param {SchemaModel} parentClass
   * @param {string} propertyName
   */
  static from(parentClass, propertyName) {
    try {
      return new InheritedValue(parentClass, propertyName);
    } catch (e) {
      console.error(`Falling back to RequiredValue because of error: ${e.message}`);
      return RequiredValue;
    }
  }
}

/**
 * Defines a required value in a schema model
 * like so:
 * @example
 * class SomeModel extends SchemaModel {
 *   constructor() {
 *     super({
 *       someProperty: RequiredValue
 *     });
 *   }
 * }
 */
export class RequiredValue extends AbstractSchemaValue {
  /**
   * Called when RequiredValue was never set
   * @param {SchemaModel} model on which prop existed
   * @param {string} prop name for which get was called
   * @throws Error
   */
  get(model, prop) {
    throw new Error(`Schema "${model.constructor.name}" missing required value "${prop}"`);
  }
}
