export default {
  /**
   * PLAYER_CONFIG_IGNORED_BY_ORGANIZATION_CONFIG
   * @param {string} playerConfigName
   * @param {string} organizationConfingName
   * @returns {string} Warning message: "Player ${playerConfigName} config is ignored as ${organizationConfingName} is
   * disabled for the organization"
   */
  PLAYER_CONFIG_IGNORED_BY_ORGANIZATION_CONFIG: (playerConfigName, organizationConfingName) =>
    `Player ${playerConfigName} config is ignored as ${organizationConfingName} is disabled for the organization`,
};
