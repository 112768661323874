import { SchemaModel, RequiredValue, InheritedValue } from './SchemaModel';

/**
 * Top level version product container
 */
class VersionedProduct {
  /**
   * Creates a new versioned product
   * @param {string} version
   */
  constructor(version) {
    if (!this._schemaVersions[version]) {
      throw new Error(`Trying to create a product of unsupported version ${version}`);
    }

    this._version = version;
  }

  /**
   * List of available schema versions
   * and mappings to classes to construct
   * @return {object}
   */
  get _schemaVersions() {
    return {
      3: LocalizedProduct,
    };
  }

  /**
   * Create a product
   * @param {string} version
   * @return {SchemaModel}
   */
  _createProduct(version) {
    const versionedFactory = this._schemaVersions[version];
    return new versionedFactory();
  }

  /**
   * Public factory getter
   * @return {SchemaModel}
   */
  get() {
    if (!this._product) {
      this._product = this._createProduct(this._version);
      this._product.decorate('_schemaVersion', this._version);
    }

    return this._product;
  }

  /**
   * Public factory getter, appending productId as a decorator (_id)
   * @param {string} productId
   * @return {SchemaModel}
   */
  getWithId(productId) {
    return this.get().decorate('_id', productId);
  }
}

/**
 * Top level product spec
 */
class LocalizedProduct extends SchemaModel {
  static get model() {
    return {
      currency: RequiredValue,
      locale: RequiredValue,
      product: Product,
      vendor: RequiredValue,
    };
  }

  /**
   * Inherit name and product reference
   * from placeholder (template)
   * @return {LocalizedProduct} chainable
   * @public
   */
  inheritFromPlaceholder() {
    return this.product((p) => p.name('').sku('')).decorate('_fromTemplate', true);
  }

  /**
   * Re-write the public url of the product
   * - Not part of spec-3 but can be used in product hydration
   *   to allow different product urls for different markets
   * @param {string} url set the public url of the product
   * @return {LocalizedProduct} chainable
   * @public
   */
  publicUrl(url) {
    return this.decorate('_publicUrl', url);
  }

  /**
   * Can hide a product from rendering in the player
   * @param {Boolean} isHidden hide the product if true
   * @return {LocalizedProduct} chainable
   * @public
   */
  hidden(isHidden) {
    return this.decorate('_hidden', isHidden);
  }
}

/**
 * Defines product section in LocalizedProduct
 */
class Product extends SchemaModel {
  static get model() {
    return {
      // brandName is required in the back-end version of product spec 3
      // however, front-end no longer requires it
      // so we kindly allow it to default to a string
      // and be null if needed
      brandName: '',
      defaultVariationIndex: 0,
      description: '',
      name: RequiredValue,
      sku: RequiredValue,
      introduction: '',
      variations: [Variation],
    };
  }
}

/**
 * Defines a variation
 */
class Variation extends SchemaModel {
  static get model() {
    return {
      attributes: VariationAttributes,
      imageUrls: [],
      name: RequiredValue,
      sku: RequiredValue,
      sizes: [Size],
    };
  }
}

/**
 * Variation attributes
 */
class VariationAttributes extends SchemaModel {
  static get model() {
    return {
      colorName: InheritedValue.from(Variation, 'name'),
      colorHexCode: null,
      colorImage: null,
    };
  }
}

/**
 * Size definition
 */
class Size extends SchemaModel {
  static get model() {
    return {
      name: RequiredValue,
      inStock: true, // not really required - default we assume in stock
      price: Price,
      sku: RequiredValue,
    };
  }
}

/**
 * Price definition
 */
class Price extends SchemaModel {
  static get model() {
    return {
      currency: InheritedValue.from(LocalizedProduct, 'currency'),
      current: RequiredValue,
      original: null,
      perUnit: null,
      unitAmount: null,
      unitDisplayName: null,
    };
  }
}

export { VersionedProduct };
