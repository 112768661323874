import { PLAYER_BUTTON_BEHAVIORS } from '../../constants';
import { BambuserLiveShoppingConfiguration } from '../BambuserLiveShoppingConfiguration';

/**
 * Custom configuration for ELC brands
 */
export class ELCBambuserLiveShoppingConfiguration extends BambuserLiveShoppingConfiguration {
  /**
   * Default configuration
   * - merged with provided configuration
   */
  get defaultConfiguration() {
    const defaultConfiguration = super.defaultConfiguration;

    return {
      ...defaultConfiguration,

      // ELC brands don't like cookies by default
      enableFirstPartyCookies: false,

      // ELC brands like miniplayer by default
      buttons: {
        ...defaultConfiguration.buttons,

        product: PLAYER_BUTTON_BEHAVIORS.MINIMIZE,
        dismiss: PLAYER_BUTTON_BEHAVIORS.MINIMIZE,
      },
    };
  }
}
