export class Logger {
  constructor({ debug, isDev, logPrefix } = {}) {
    this._debug = debug;
    this._isDev = isDev;
    this._logPrefix = logPrefix;
  }

  log() {
    if (arguments.length) {
      arguments[0] = `${this._logPrefix}${arguments[0]}`;
    }
    this._debug && console.log(...arguments);
  }

  logDev() {
    if (arguments.length) {
      arguments[0] = `${this._logPrefix}${arguments[0]}`;
    }
    this._isDev && console.log(...arguments);
  }

  logProd() {
    if (arguments.length) {
      arguments[0] = `${this._logPrefix}${arguments[0]}`;
    }
    console.log(...arguments);
  }

  warnProd() {
    if (arguments.length) {
      arguments[0] = `${this._logPrefix}${arguments[0]}`;
    }
    console.warn(...arguments);
  }

  error() {
    if (arguments.length) {
      arguments[0] = `${this._logPrefix}${arguments[0]}`;
    }
    console.error(...arguments);
  }

  warnProdDeprecated() {
    if (arguments.length) {
      arguments[0] = `${this._logPrefix} DEPRECATED: ${arguments[0]}`;
    }
    console.warn(...arguments);
  }
}
